import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Select } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";
import { useSelector } from "react-redux";

function AddSalaryPayment() {
  const { terms } = useSelector((state) => state.terms);
  // const {months} = useSelector((state) => state.months);
  const [staff, setStaff] = useState([]);
  const [loading, setLoading] = useState(false);
  const [allowances, setAllowances] = useState([]);
  const [errorMessage, setErrorMessage] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [months, setMonths]  = useState([]);
  const [formData, setFormData] = useState({
    staff_id: "",
    amount: "",
    term_id: "",
    payment_type: "",
    payment_method: "",
    allowance_id: "",
    month_id:"",
    payment_date:""
  });
  
  const filterStaffOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const staffOptions = staff?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const filterAllowanceOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const allowanceOption = allowances?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const handleTermChange = (e) => {
    const selectedMethodId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      term_id: selectedMethodId,
    }));
  };
  const handlePaymentTypeChange = (e) => {
    const selectedMethodId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      payment_type: selectedMethodId,
    }));
  };
  const onChangeStaff = (value) => {
    setFormData({
      ...formData,
      staff_id: value,
    });
  };
  const onChangeAllowance = (value) => {
    setFormData({
      ...formData,
      allowance_id: value,
    });
  };

  const handlePaymentMethodChange = (e) => {
    const selectedMethodId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      payment_method: selectedMethodId,
    }));
  };
  const handleMonthChange = (value)=>{
    setFormData({
      ...formData,
      month_id:value
    })
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const validateForm = () => {
    const newErrors = {};

    if (!formData.amount) {
      newErrors.amount = "Amount paid is required";
    }
    if (!formData.staff_id) {
      newErrors.student_name = "Staff member is required";
    }
    if (!formData.term_id) {
      newErrors.term_id = "School Term is Required";
    }
    if (!formData.payment_method) {
      newErrors.fee_payment_method_id = "Payment Method is Required";
    }
    if (!formData.payment_type) {
      newErrors.reg_no = "payment type is Required";
    }
    if(!formData.payment_date){
      newErrors.reg_no = "payment Date is Required";

    }

    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const getStaff = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setStaff(response.staff);
      // toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/staff`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const fetchPaymentMethods = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setPaymentMethods(response.feesPaymentMethods);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/feePaymentMethods`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getMonths =()=>{
    setLoading(true);
    const onSuccess =(response)=>{
      setMonths(response.months);
      setLoading(false);
    }
    const onError =()=>{
      toast.error("failed to retrieve months")
      setLoading(false);

    }
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/months`,
      "",
      "",
      onSuccess,
      onError
    );
  }

  const filterMonthOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const monthOptions = months?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const getAllowances = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setAllowances(response.allowances);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("failed to get allowances");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/allowances`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const handleSubmit = async () => {
    if (!validateForm) {
      return;
    }
    setLoading(true);
    const onSuccess = (response) => {
      toast.success(response.message);
      setFormData({
        staff_id: "",
        amount: "",
        term_id: "",
        payment_type: "",
        payment_method: "",
        allowance_id: "",
        month_id:"",
        payment_date:""
      })
      setLoading(false);

    };
    const onError = (error) => {
      toast.error(error?.response?.data?.error);
      setLoading(false);
    };
    apiRequest(
      "post",
      `${API_BASE_URL}payroll/staff-payments`,
      formData,
      "",
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getStaff();
    fetchPaymentMethods();
    getAllowances();
    getMonths();
  }, []);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1>Add Salary Payments</h1>
          <div className="max-w-2xl mx-auto">
            <form className="bg-white shadow-sm rounded px-8 pt-6 pb-8 mb-4">
              <h1 className=" text-lg justify-center items-center flex font-semibold mb-3">
                Enter Payment details
              </h1>
              <div className="relative h-11 w-full min-w-[200px] mt-3">
                <label className="">Select Staff Member</label>
                <div className="relative h-11 w-full min-w-[200px]">
                  <Select
                    showSearch
                    placeholder="Select Staff Member"
                    optionFilterProp="children"
                    className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                    onChange={onChangeStaff}
                    filterOption={filterStaffOption}
                    size="large"
                    options={staffOptions}
                  />
                </div>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-8">
                <select
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.term_id && "border-red-500"
                  }`}
                  id="fee_payment_method_id"
                  name="fee_payment_method_id"
                  value={formData.term_id}
                  onChange={handleTermChange}
                >
                  <option value="">Select School Term</option>
                  {terms.map((method) => (
                    <option key={method.id} value={method.id}>
                      {method.termName}
                    </option>
                  ))}
                </select>
                {errorMessage.term_id && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.term_id}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  School Term *
                </label>
              </div>
              <div className=" mb-9 relative h-11 w-full min-w-[200px] ">
                <label className="">Select Month</label>
                <div className=" relative h-11 w-full min-w-[200px]">
                  <Select
                    showSearch
                    placeholder="Select Month"
                    optionFilterProp="children"
                    className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                    onChange={handleMonthChange}
                    filterOption={filterMonthOption}
                    size="large"
                    options={monthOptions}
                  />
                </div>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Payment Type
                </label>
                <select
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.payment_type && "border-red-500"
                  }`}
                  id="payment_type"
                  name="payment_type"
                  value={formData.payment_type}
                  onChange={handlePaymentTypeChange}
                >
                  <option value="">Select Payment Type</option>
                  <option key="salary" value="salary">
                    Salary Payment
                  </option>
                  <option key="allowance" value="allowance">
                    Allowance Payment
                  </option>
                </select>
                {errorMessage.payment_type && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.payment_type}
                  </span>
                )}
               
              </div>
              {formData.payment_type === "allowance" && (
                <div className=" mb-7 relative h-11 w-full min-w-[200px]">
                  <label className="">Select Allowance</label>
                  <div className="relative h-11 w-full min-w-[200px]">
                    <Select
                      showSearch
                      placeholder="Select Allowance"
                      optionFilterProp="children"
                      className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                      onChange={onChangeAllowance}
                      filterOption={filterAllowanceOption}
                      size="large"
                      options={allowanceOption}
                    />
                  </div>
                </div>
              )}
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <select
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.payment_method && "border-red-500"
                  }`}
                  id="payment_method"
                  name="payment_method"
                  value={formData.payment_method}
                  onChange={handlePaymentMethodChange}
                >
                  <option value="">Select Payment Method</option>
                  {paymentMethods.filter((x) => parseInt(x.type_id) === 2).map((method) => (
                    <option key={method.id} value={method.id}>
                      {method.name}
                    </option>
                  ))}
                </select>
                {errorMessage.payment_method && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.payment_method}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Payment Method *
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.amount && "border-red-500"
                  }`}
                  id="amount"
                  name="amount"
                  type="number"
                  min="500"
                  step="500"
                  placeholder=" "
                  value={formData.amount}
                  onChange={handleChange}
                />
                {errorMessage.amount && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.amount}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Amount *
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.payment_date && "border-red-500"
                  }`}
                  id="payment_date"
                  name="payment_date"
                  type="date"
                  placeholder=" "
                  value={formData.payment_date}
                  onChange={handleChange}
                />
                {errorMessage.payment_date && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.payment_date}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Payment Date *
                </label>
              </div>
              <div className="flex justify-center">
                <button
                  type="button"
                  disabled={loading}
                  className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                  ) : (
                    "Add Payment"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddSalaryPayment;
