import React, { useState, useEffect, useRef } from "react";
import { Modal, Tabs, Select, Table } from "antd";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";

const { TabPane } = Tabs;

function StaffModal({ open, setOpen, staff }) {
  const [loading, setLoading] = useState(false);
  const [staffDetails, setStaffDetails] = useState([]);
  const [activeTab, setActiveTab] = useState("details");
  const [exemptTax, setExemptTax] = useState({});
  const [refresh, setRefresh] = useState(false);
  const [months, setMonths] = useState([]);
  const [staffPayments, setStaffPayments] = useState([]);
  const [formData, setFormData] = useState({
    month_id: "",
  });

  const payslipRef = useRef(null);
  const monthlyStatementRef = useRef(null);

  const filterMonthOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const monthOptions = months?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleMonthChange = (value) => {
    setFormData({
      ...formData,
      month_id: value,
    });
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: "Term ID",
      dataIndex: ["term", "termName"],
      key: "term_id",
      width: 120,
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",
      width: 120,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      width: 120,
    },
    {
      title: "Payment Method",
      dataIndex: ["payment_method", "name"],
      key: "payment_method",
      width: 120,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      fixed: "right",
      render: (amount) => parseInt(amount).toLocaleString(),
      width: 120,
    },
  ];

  const getStaffDetails = () => {
    setLoading(true);
    const data = JSON.stringify({
      staff_id: staff?.id,
    });
    const onSuccess = (response) => {
      setStaffDetails(response.data);
      setLoading(false);
    };
    const onError = () => {
      toast.error("Failed to fetch staff details");
      setLoading(false);
    };
    apiRequest(
      "post",
      `${API_BASE_URL}payroll/calculate-salary`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const getMonths = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setMonths(response.months);
      setLoading(false);
    };
    const onError = () => {
      toast.error("Failed to retrieve months");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/months`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const formatAmount = (amount) => {
    return `UGX ${parseInt(amount).toLocaleString()}`;
  };

  const updateTaxLimitations = () => {
    const onSuccess = (response) => {
      toast.success(response.message);
      setRefresh(!refresh);
    };
    const onError = (error) => {
      toast.error("Tax exemption failed");
    };
    const data = {
      staff_id: staff?.id,
      tax_exempt: exemptTax.tax_exempt,
    };
    apiRequest(
      "post",
      `${API_BASE_URL}payroll/tax-exempt`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const getPayments = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setStaffPayments(response);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(error?.response?.data?.error);
      setLoading(false);
    };
    const params = {
      staff_id: staff?.id,
      month_id: formData.month_id,
    };
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/staffmemberPayments`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    if (formData.month_id !== "") {
      getPayments();
    }
  }, [formData.month_id]);

  useEffect(() => {
    if (staff) {
      getStaffDetails();
      getMonths();
    }
  }, [staff, refresh]);

  useEffect(() => {
    if (staff) {
      setExemptTax({
        tax_exempt: staff?.tax_exempt,
      });
    }
  }, [staff]);

  const generatePayrollSlip = async () => {
    setLoading(true);

    try {
      const response = await axios.post(
        `${API_BASE_URL}payroll/slip`,
        { staff_id: staff?.id },
        {
          responseType: "blob", // Important to handle binary data
        }
      );
      setLoading(false);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payroll_slip.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("Payslip downloaded successfully!");
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred while generating the payroll slip.");
    }
  };

  const generatePayrollSummary = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        `${API_BASE_URL}payroll/summary`,
        {
          staff_id: staff?.id,
          month_id: formData.month_id,
        },
        { responseType: "blob" }
      );
      setLoading(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "payroll_summary.pdf");
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      toast.error("An error occurred while generating the payroll summary.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Modal
        width={1000}
        title="Staff Details"
        open={open}
        onCancel={handleCancel}
      >
        <Tabs centered activeKey={activeTab} onChange={handleTabChange}>
          <TabPane
            tab={<span className="text-2xl text-blue-600">Details</span>}
            key="details"
          >
            <div>
              <p>
                <span className="text-lg font-semibold">Name</span> :{" "}
                <span className="text-xl text-blue-500">{staff?.name}</span>
              </p>
              <div className="mt-5 flex">
                <label className="inline-flex items-center">
                  <input
                    type="checkbox"
                    name="apply_nssf"
                    className="form-checkbox h-5 w-5 text-indigo-600"
                    checked={exemptTax.tax_exempt === 1}
                    onChange={(e) =>
                      setExemptTax({
                        ...exemptTax,
                        tax_exempt: e.target.checked ? 1 : 0,
                      })
                    }
                  />
                  <span className="ml-2 text-gray-700 font-bold">
                    Exempt From Tax
                  </span>
                </label>
                <button
                  disabled={loading}
                  className="m2 ml-5 border border-red-400 rounded-lg text-red-400 hover:bg-red-400 hover:text-white py-1 px-4"
                  onClick={updateTaxLimitations}
                >
                  {loading ? "Updating ..." : " Update"}
                </button>
              </div>
            </div>
          </TabPane>
          <TabPane
            tab={<span className="text-2xl text-green-600">Salary Info</span>}
            key="salaryInfo"
          >
            <div ref={payslipRef}>
              <div className="max-w-2xl mx-auto text-xl bg-white shadow-md rounded-lg overflow-hidden">
                <div className="p-4">
                  <h2 className="text-lg font-semibold text-blue-600 mb-4">
                    Payslip Details
                  </h2>
                  <div className="flex flex-col mb-6">
                    <h3 className="text-lg font-semibold text-green-600 mb-2">
                      Allowances Breakdown
                    </h3>
                    <ul>
                      {staffDetails?.allowances?.map((allowance, index) => (
                        <li key={index} className="mb-2">
                          <div className="flex justify-between text-sm lg:text-lg">
                            <p>{allowance.name}:</p>
                            <p className="text-green-500">
                              {formatAmount(allowance.amount)}
                            </p>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="flex flex-col mb-6">
                    <h3 className="text-lg font-semibold text-blue-600 mb-2">
                      Salary Details
                    </h3>
                    <div className="flex justify-between">
                      <p>Basic Salary:</p>
                      <p className="text-green-500">
                        {formatAmount(staffDetails.base_salary)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p>Gross Salary(Allowances Included):</p>
                      <p className="text-green-500">
                        {formatAmount(staffDetails?.total_salary)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col mb-6">
                    <h3 className="text-lg font-semibold text-red-600 mb-2">
                      Deductions Breakdown
                    </h3>
                    <div className="flex justify-between">
                      <p>PAYE:</p>
                      <p className="text-red-500">
                        {formatAmount(staffDetails?.tax_deductions?.PAYE ?staffDetails?.tax_deductions?.PAYE  :0)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p>NSSF:</p>
                      <p className="text-red-500">
                        {formatAmount(staffDetails?.tax_deductions?.NSSF ?staffDetails?.tax_deductions?.NSSF : 0)}
                      </p>
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <h3 className="text-lg font-semibold text-blue-600 mb-2">
                      Net Pay
                    </h3>
                    <div className="flex justify-between">
                      <p>Net Salary:</p>
                      <p className="text-green-500">
                        {formatAmount(staffDetails?.net_pay)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              className="mt-4 border border-blue-400 rounded-lg text-blue-400 hover:bg-blue-400 hover:text-white py-1 px-4"
              onClick={generatePayrollSlip}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                " Generate Payslip PDF"
              )}
            </button>
          </TabPane>
          <TabPane
            tab={<span className="text-2xl text-red-600">Payments</span>}
            key="payments"
          >
            <div className="mb-9 relative h-11 w-full min-w-[200px]">
              <label className="text-blue-600">Select Month</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select Month"
                  optionFilterProp="children"
                  className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                  onChange={handleMonthChange}
                  filterOption={filterMonthOption}
                  size="large"
                  options={monthOptions}
                />
              </div>
            </div>

            <div className="container mx-auto p-4" ref={monthlyStatementRef}>
              {formData.month_id !== "" && (
                <>
                  <h1 className="text-2xl font-bold text-blue-600 mb-4">
                    Payroll Summary for {staffPayments?.month}
                  </h1>
                  <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-4">
                    <h2 className="text-xl font-semibold text-green-600">
                      Summary
                    </h2>
                    <p className=" text-lg">
                      <strong className="text-blue-600">Total Salary:</strong>{" "}
                      UGX{" "}
                      <span className="font-bold">
                        {staffPayments?.total_salary?.toLocaleString()}
                      </span>
                    </p>
                    <p className=" text-lg">
                      <strong className="text-blue-600">
                        Total Amount Paid:
                      </strong>{" "}
                      UGX{" "}
                      <span className="font-bold">
                        {staffPayments?.total_amount?.toLocaleString()}
                      </span>
                    </p>
                    <p className=" text-lg">
                      <strong className="text-red-600">Balance:</strong> UGX{" "}
                      <span className="font-bold">
                        {staffPayments?.balance?.toLocaleString()}
                      </span>
                    </p>
                  </div>
                </>
              )}

              <div className="bg-gray-100 p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold text-green-600 mb-2">
                  Payments
                </h2>
                <Table
                  columns={columns}
                  pagination={{
                    pageSize: 50,
                  }}
                  scroll={{
                    y: 1700,
                  }}
                  dataSource={loading ? [] : staffPayments?.staffMemberPayments}
                  summary={() => (
                    <Table.Summary fixed="bottom">
                      <Table.Summary.Row className=" bg-gray-600 text-white font-bold">
                        <Table.Summary.Cell colSpan={5}>
                          Total
                        </Table.Summary.Cell>
                        <Table.Summary.Cell>
                          <span>
                            {parseInt(
                              staffPayments?.total_amount
                            ).toLocaleString()}
                          </span>
                        </Table.Summary.Cell>
                        {/* <Table.Summary.Cell colSpan={2}></Table.Summary.Cell> */}
                      </Table.Summary.Row>
                    </Table.Summary>
                  )}
                  rowKey="id"
                />
              </div>
            </div>
            <button
              className="mt-4 border border-blue-400 rounded-lg text-blue-400 hover:bg-blue-400 hover:text-white py-1 px-4"
              onClick={generatePayrollSummary}
            >
               {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                " Generate Monthly Payroll Summary PDF"
              )}
             
            </button>
          </TabPane>
        </Tabs>
      </Modal>
    </div>
  );
}

StaffModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  staff: PropTypes.object.isRequired,
};

export default StaffModal;
