import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Divider, Input, Select, Table, Button } from "antd";
import { useSelector } from "react-redux";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";

function CreateFeeStructure() {  
  const {terms} = useSelector((state) => state.terms);
  const {classes} = useSelector((state) => state.classes);
  const {years} = useSelector((state) => state.years);
  const termColors = ["bg-blue-400", "bg-green-400", "bg-red-400"]; 
  const [classStudents, setClassStudents] = useState([]);
  const [feeTypes, setFeeTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedFee, setSelectedFee] = useState(null);
  const [amount, setAmount] = useState("");
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedEntries, setSelectedEntries] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    class: "",
    year: "",
    fees: [],
    assignment_type: "",
    special_student_reg_no: "",
    special_students :[]
  });
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedRowKeysBeforeSearch, setSelectedRowKeysBeforeSearch] =
    useState([]);
  const [filteredStudents, setFilteredStudents] = useState(null);

  const [errorMessage, setErrorMessage] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const onChangeClass = (value) => {
    setFormData({
      ...formData,
      class: value,
    });
  };
  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };
  const onChangeAssignmentType = (value) => {
    setFormData({
      ...formData,
      assignment_type: value,
    });
  };

  const onSearch = (value) => {
    console.log("search:", value);
  };
  // Function to handle fee selection from dropdown
  const handleFeeSelect = (fee, term) => {
    setSelectedFee(fee);
    setModalVisible(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setSelectedFee(null);
    setAmount("");
    setModalVisible(false);
  };

  // Function to handle amount input change
  const handleAmountChange = (value) => {
    setAmount(value);
  };

  // Function to handle modal confirm
  const handleModalConfirm = () => {
    if (!selectedFee || !amount || !selectedTerm) {
      // Show error if fee, amount, or term is not selected
      toast.error("Please select fee, amount, and term");
      return;
    }
    // Add the selected entry to the array
    setSelectedEntries([
      ...selectedEntries,
      { term_id: selectedTerm, id: selectedFee, amount },
    ]);

    // Close modal and reset state
    handleModalClose();
  };
  // Filter `option.label` match the user type `input`
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  /**
   * Validate form
   * @returns false / true
   */
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name is required";
    }
    if (!formData.class) {
      newErrors.class = "class is Required";
    }
    if (!formData.year) {
      newErrors.year = "year is Required";
    }
    // if (!formData.term) {
    //   newErrors.term = "term is Required";
    // }
    if (step === 2 && formData?.fees.length === 0) {
      newErrors.fees = "At least one fee type must be selected";
    }

    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (validateForm()) {
      setStep(step + 1);
    }
  };
  const handlePrevious = () => {
    setStep(step - 1);
  };

  /**
   *  Transform classes data into options array
   */
  const classOptions = classes?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  /**
   *  Transform years data into options array
   */
  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));

  const filterClassOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());


 
  const fetchFeeTypes = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setFeeTypes(response.feeTypes);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/feeTypes`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleDeleteEntry = (index) => {
    const updatedEntries = [...selectedEntries];
    updatedEntries.splice(index, 1); // Remove the entry at the specified index
    setSelectedEntries(updatedEntries); // Update the selectedEntries state
  };

  

  const fetchClassStudents = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setClassStudents(response.students);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("Failed to retreive Students");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/${formData.class}/classStudents`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  /**
   * @description subits data to backend for processing
   */

  const handleSubmit = () => {
    setLoading(true);
    if (!validateForm()) {
      toast.error(errorMessage.fees);
    }
    setLoading(true)

    let data = JSON.stringify({
      name: formData.name,
      level_class_id: formData.class,
      assignment_type:formData.assignment_type,
      term_id: formData.term,
      year_id: formData.year,
      special_student_reg_no: formData.special_students,
      fees: formData.fees,
    });
    const onSuccess = (response) => {
      toast.success(response.message);
      setFormData({
        name: "",
        class: "",
        year: "",
        term: "",
        special_student_reg_no: "",
        special_students:[],
        fees: [],
      });
      setSelectedEntries([]);
      setLoading(false);
      setStep(1);
    };

    const onError = (error) => {
      const errorMessages = Object.values(
        error?.response?.data?.message
      ).flatMap((messageArr) => messageArr);
      const formattedErrorMessage = errorMessages.join("\n");
      toast.error(formattedErrorMessage);
      setLoading(false);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}create/fee-structures`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  /**
   * get student based on reg no
   */
  const filterData = [];

  filteredStudents &&
    filteredStudents.forEach((student) => {
      filterData.push({
        key:student.regno.toString(), // Use a unique key for each row
        firstname: student.firstname,
        surname: student.surname,
        currentClass: student.currentClass,
        regno: student.regno,
      });
    });

  const start = () => {
    setLoading(true);
    // ajax request after empty completing
    setTimeout(() => {
      setSelectedRowKeys([]);
      setLoading(false);
    }, 1000);
  };
  // Inside the onSelectChange function
  const onSelectChange = (selectedRowKeys) => {
    // Combine the newly selected row keys with the current selectedRowKeys
    setSelectedRowKeys((prevSelectedRowKeys) => {
      const updatedSelectedRowKeys = [
        ...prevSelectedRowKeys,
        ...selectedRowKeys,
      ];
      // Remove duplicates and return the updated array
      return [...new Set(updatedSelectedRowKeys)];
    });

    // If search query is not empty, update selectedRowKeysBeforeSearch
    if (formData.special_student_reg_no.trim() !== "") {
      setSelectedRowKeysBeforeSearch((prevSelectedRowKeys) => {
        // Combine the newly selected row keys with the selectedRowKeysBeforeSearch
        const updatedSelectedRowKeys = [
          ...prevSelectedRowKeys,
          ...selectedRowKeys,
        ];
        // Remove duplicates and return the updated array
        return [...new Set(updatedSelectedRowKeys)];
      });
    }
  };
  const hasSelected = selectedRowKeys.length > 0;
  const columns = [
    {
      title: "First Name",
      key: "firstname",
      dataIndex: "firstname",
    },
    {
      title: "Surname Name",
      key: "surname",
      dataIndex: "surname",
    },
    {
      title: "Class",
      key: "currentClass",
      dataIndex: "currentClass",
    },
    {
      title: "Reg No.",
      key: "regno",
      dataIndex: "regno",
    },
  ];
  useEffect(() => {
    if (formData.special_student_reg_no && formData.special_student_reg_no.trim() !== "") {
      // If search query is not empty, filter the class students
      const filtered = classStudents.filter((student) =>
        student.regno
          .toLowerCase()
          .includes(formData.special_student_reg_no.toLowerCase())
      );
      setFilteredStudents(filtered); // Update filteredStudents with filtered data
    } else {
      setFilteredStudents(null); // Set filteredStudents to null when search query is empty
    }
    setSelectedRowKeysBeforeSearch(selectedRowKeys);
  }, [formData.special_student_reg_no, selectedRowKeys]);

  useEffect(() => {
    fetchFeeTypes();
  }, []);

  useEffect(() => {
    if (formData.assignment_type === "student") {
      fetchClassStudents();
    }
  }, [formData.assignment_type, formData.class]);

  useEffect(()=>{
    setFormData({
      ...formData,
      fees: selectedEntries,
      special_students:selectedRowKeys

    });

  },[selectedEntries, selectedRowKeys]);


  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            Register Fee Structure
          </h1>

          <div className=" mx-auto">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <h1 className=" text-lg justify-center items-center flex font-semibold mb-3">
                {step === 1
                  ? "Step 1 : Enter Details About Fees Structure"
                  : " Step 2 : Add Fees Types to the Structure"}
              </h1>
              <Divider />
              {step === 1 && (
                <>
                  {" "}
                  <div className="mb-4 relative h-11 w-full min-w-[200px]">
                    <input
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        errorMessage.name && "border-red-500"
                      }`}
                      id="name"
                      name="name"
                      type="text"
                      placeholder=" "
                      value={formData.name}
                      onChange={handleChange}
                    />
                    {errorMessage.name && (
                      <span className="text-xs text-red-500  flex justify-center ">
                        {errorMessage.name}
                      </span>
                    )}
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Name of Fee Structure *
                    </label>
                  </div>
                  <div className="relative h-11 w-full min-w-[200px] mt-3">
                    <label className="">Select Class</label>
                    <div className="relative h-11 w-full min-w-[200px]">
                      <Select
                        showSearch
                        placeholder="Select Class"
                        optionFilterProp="children"
                        className={`
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                             errorMessage.class && "border-red-500"
                           }`}
                        onChange={onChangeClass}
                        onSearch={onSearch}
                        filterOption={filterClassOption}
                        size="large"
                        options={classOptions}
                      />
                      {errorMessage.class && (
                        <span className="text-xs text-red-500  flex justify-center ">
                          {errorMessage.class}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="relative h-11 w-full min-w-[200px] mt-6">
                    <label className="">Select Year</label>
                    <div className="relative h-11 w-full min-w-[200px]">
                      <Select
                        showSearch
                        placeholder="Select Year"
                        optionFilterProp="children"
                        className={`
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                             errorMessage.year && "border-red-500"
                           }`}
                        onChange={onChangeYear}
                        onSearch={onSearch}
                        filterOption={filterYearOption}
                        size="large"
                        options={yearOptions}
                      />
                      {errorMessage.year && (
                        <span className="text-xs text-red-500 flex justify-center ">
                          {errorMessage.year}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="relative h-11 w-full min-w-[200px] mt-6 mb-11">
                    <label className="">Apply To</label>

                    <Select
                      showSearch
                      placeholder="Select Group to apply to"
                      optionFilterProp="children"
                      className={`
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                             errorMessage.assignment_type && "border-red-500"
                           }`}
                      onChange={onChangeAssignmentType}
                      onSearch={onSearch}
                      filterOption={filterOption}
                      options={[
                        {
                          value: "class",
                          label: `Apply to Whole Class`,
                        },
                        {
                          value: "student",
                          label: `Apply to Specific Student in a class`,
                        },
                      ]}
                    />
                  </div>
                  <div className="relative h-11 w-full min-w-[200px] mt-6 mb-6">
                    {formData.assignment_type === "student" && (
                      <>
                        <input
                          className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                            errorMessage.special_student_reg_no &&
                            "border-red-500"
                          }`}
                          id="special_student_reg_no"
                          name="special_student_reg_no"
                          type="text"
                          placeholder=" "
                          value={formData.special_student_reg_no}
                          onChange={handleChange}
                        />
                        {errorMessage.special_student_reg_no && (
                          <span className="text-xs text-red-500  flex justify-center ">
                            {errorMessage.special_student_reg_no}
                          </span>
                        )}
                        <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                          Enter Student Registration No
                        </label>
                      </>
                    )}
                  </div>
                  {/* student table  */}
                 {formData.assignment_type === "student" && <div className=" mt-24">
                    <div
                      style={{
                        marginBottom: 16,
                      }}
                    >
                      <Button
                        type="primary"
                        onClick={start}
                        disabled={!hasSelected}
                        loading={loading}
                      >
                        Reload
                      </Button>
                      <span
                        style={{
                          marginLeft: 8,
                        }}
                      >
                        {hasSelected
                          ? `Selected ${selectedRowKeys.length} items`
                          : ""}
                      </span>
                    </div>
                    <Table
                      rowSelection={{
                        selectedRowKeys,
                        onChange: onSelectChange,
                      }}
                      columns={columns}
                      dataSource={filterData}
                    />
                  </div>}
                </>
              )}
              {step === 2 && (
                <div className="flex flex-row justify-center mb-10 mt-11 ">
                  {terms.map((term, index) => {
                    const colorIndex = index % termColors.length;
                    const colorClass = termColors[colorIndex];
                    return (
                      <div
                        key={index}
                        className="group w-2/3 relative cursor-pointer overflow-hidden bg-white px-6 pt-10 pb-8 shadow-xl ring-1 ring-gray-900/5 transition-all duration-300  sm:mx-2 sm:max-w-sm sm:rounded-lg sm:px-10"
                      >
                        <span>Term {term.termName}</span>
                        {/* Other JSX code */}
                        <div className="space-y-6 pt-5 text-base leading-7 text-gray-600 transition-all duration-300">
                          <div>
                            {/* Dropdown for selecting fee */}
                            <label>Select Fee</label>
                            <Select
                              showSearch
                              placeholder="Select Fee"
                              optionFilterProp="children"
                              onChange={(value) => {
                                handleFeeSelect(value, term.id);
                                setSelectedTerm(term.id);
                              }}
                              className="mb-3 w-full"
                            >
                              {feeTypes.map((fee, index) => (
                                <Select.Option key={index} value={fee.id}>
                                  {fee.name}
                                </Select.Option>
                              ))}
                            </Select>
                            {/* Modal for entering amount */}
                            <Modal
                              title={`Enter Amount for ${selectedFee}`}
                              open={modalVisible}
                              onOk={handleModalConfirm}
                              onCancel={handleModalClose}
                            >
                              <Input
                                type="number"
                                value={amount}
                                onChange={(e) =>
                                  handleAmountChange(e.target.value)
                                }
                                placeholder="Enter Amount"
                              />
                            </Modal>
                            {/* Input field */}
                            <div>
                              {selectedEntries.map((entry, index) => {
                                if (entry.term_id === term.id) {
                                  return (
                                    <div
                                      key={index}
                                      className="selected-entry list-item bg-gray-200 px-2 py-4 rounded-lg mt-6"
                                    >
                                      <span className="font-semibold">
                                        {
                                          feeTypes.find(
                                            (fee) =>
                                              parseInt(fee.id) ===
                                              parseInt(entry.id)
                                          )?.name
                                        }{" "}
                                        Fee
                                      </span>
                                      <span> : {entry.amount}</span>
                                      <div className="float-end">
                                        <button
                                          onClick={() =>
                                            handleDeleteEntry(index)
                                          }
                                          className=" select-none rounded-lg bg-red-600 py-2 px-4 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                                        >
                                          Delete
                                        </button>{" "}
                                      </div>
                                    </div>
                                  );
                                }
                                return null;
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {step === 1 && (
                <div className="flex justify-center">
                  <button
                    className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                    onClick={handleNext}
                  >
                    Next
                  </button>
                </div>
              )}
              {step === 2 && (
                <div className="flex justify-center">
                  <button
                    className="mt-6 mr-2 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                    type="button"
                    onClick={handlePrevious}
                    disabled={loading}
                  >
                    Previous
                  </button>
                  <button
                    type="button"
                    onClick={handleSubmit}
                    disabled={loading}
                    className="mt-6  select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                  >
                    {loading ? (
                      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default CreateFeeStructure;
