import React, { useEffect, useState } from "react";
import { Table, Skeleton, Empty, Drawer, Select, Divider } from "antd";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";
import FeeStructureDetailModal from "./FeeStructureDetailModal";
import { useSelector } from "react-redux";

function ViewFeeStructure() {
  const {settings} = useSelector((state) => state.settings.settings);
  const {terms} = useSelector((state) => state.terms );
  const {years}= useSelector((state) => state.years);
  const  {classes} = useSelector((state) => state.classes);
  const [loading, setLoading] = useState(false);
  const [feeStructures, setFeeStructures] = useState([]);
  const [feeStructure, setFeeStructure] = useState(null);
  const [openFeeStructureDetails, setOpenFeeStructureDetails] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filterTerm, setFilterTerm] = useState(null);
  const [filterClass, setFilterClass] = useState(null);
  const [filterYear, setFilterYear] = useState(null);

  const onSearch = (value) => {
    console.log("search:", value);
  };
  /**
   * @function fetchFeeStructures
   * @description fetches all fees structures  stored 
   */
  const fetchFeeStructures = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setFeeStructures(response.feeStructures);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    };
    let filterParams = {
      year_id:filterYear || settings[0]?.currentYear,
      class_id:filterClass,
      term_id:filterTerm
    }

    apiRequest(
      "get",
      `${API_BASE_URL}get/fee-structures`,
      "",
      filterParams,
      onSuccess,
      onError
    );
  };
  /**
   * @var columns
   * @description columns for fee structure table
   */
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
      width: 2,
    },
    {
      title: "Name of Fee Structure",
      dataIndex: "name",
      key: "name",
      width: 20,
    },
    {
      title: "Class",
      dataIndex: ["classes", "name"],
      key: "classes",
      width: 20,
    },
    {
      title: "Year",
      dataIndex: ["year", "yearName"],
      key: "years",
      width: 20,
    },
    {
      title: "Actions",
      key: "action",
      fixed: "right",
      width: 6,
      render: (text, record, index) => (
        <button
          onClick={() => {
            handleFeeStructureModal(record);
          }}
          className="block  px-4 py-2 m-4  hover:bg-gray-300 text-left text-white bg-gradient-to-r from-blue-400 via-blue-500 to-blue-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-200  font-medium rounded-lg text-sm"
        >
          View
        </button>
      ),
    },
  ];



  const handleFeeStructureModal = (record) => {
    setOpenFeeStructureDetails(true);
    setFeeStructure(record.id);
  };

  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    setFilterTerm(null);
    setFilterClass(null);
    setFilterYear(null);
  };

  const termOptions = terms?.map((term) => ({
    value: term.id.toString(),
    label: term.termName,
  }));
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const classOptions = classes?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const filterClassOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));
  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const onChangeClass = (value) => {
    setFilterClass(value);
  };
  const onChangeTerm = (value) => {
    setFilterTerm(value);
  };

  const onChangeYear = (value) => {
    setFilterYear(value);
  };

  useEffect(() => {
    fetchFeeStructures();
  }, []);
  useEffect(() => {
    fetchFeeStructures()
  }, [filterClass,filterTerm,filterYear]);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            View Fees Structure Table
          </h1>
          <div className="hidden lg:flex float-end">
            <button
              onClick={toggleFilters}
              className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Filters
            </button>
          </div>
          <Table
            dataSource={loading ? Array(10).fill({}) : feeStructures}
            columns={columns}
            scroll={{ x: 1500, y: 600 }}
            pagination={{ pageSize: 50 }}
            className=" whitespace-nowrap"
            locale={{
              emptyText: loading ? (
                <Skeleton active paragraph={{ rows: 16 }} />
              ) : (
                <Empty />
              ),
            }}
          />
        </div>
        {/* filters  */}
        <Drawer
          title="Filters"
          placement="right"
          closable={true}
          onClose={onClose}
          open={showFilters}
          getContainer={false}
        >
          <div className="relative h-11 w-full min-w-[200px] mt-3">
            <label className="">Select Class</label>
            <div className="relative h-11 w-full min-w-[200px]">
              <Select
                showSearch
                placeholder="Select Class"
                optionFilterProp="children"
                className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                onChange={onChangeClass}
                onSearch={onSearch}
                filterOption={filterClassOption}
                size="large"
                options={classOptions}
              />
            </div>
          </div>
          <div className="relative h-11 w-full min-w-[200px] mt-6">
            <label className="">Select Year</label>
            <div className="relative h-11 w-full min-w-[200px]">
              <Select
                showSearch
                placeholder="Select Year"
                optionFilterProp="children"
                className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                onChange={onChangeYear}
                onSearch={onSearch}
                filterOption={filterYearOption}
                size="large"
                options={yearOptions}
              />
            </div>
          </div>
          <Divider />
          <div className="flex justify-between mt-4">
            <button
              type="button"
              className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
              onClick={() => {
                setShowFilters(false);
              }}
            >
              Apply Filters
            </button>
            <button
              onClick={resetFilters}
              className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
            >
              Reset Filters
            </button>
          </div>
        </Drawer>
      </div>
      <FeeStructureDetailModal
        open={openFeeStructureDetails}
        setOpen={setOpenFeeStructureDetails}
        feeStructureId={feeStructure}
      />
    </div>
  );
}

export default ViewFeeStructure;
