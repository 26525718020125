import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Select } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";
import DefaultersTables from "./DefaultersTables";
import SchoolFeeDefaultersTable from "./SchoolFeeDefaulterTable";
import { useSelector } from "react-redux";

function FeeDefaulters() {
  const { settings } = useSelector((state) => state.settings.settings);
  const { terms } = useSelector((state) => state.terms);
  const { years } = useSelector((state) => state.years);
  const { classes } = useSelector((state) => state.classes);

  const [loading, setLoading] = useState(false);
  const [feesDefaulters, setDefaulters] = useState([]);
  const [schoolFeeDefaulters, setSchoolFeesDefaulters] = useState([]);
  const [criteria, setCriteria] = useState("");
  const [formData, setFormData] = useState({
    class: "",
    year: "",
    term: "",
    percentage: "",
  });
  const onSearch = (value) => {
    console.log("search:", value);
  };
  const handleCriteria = (e) => {
    setCriteria(e.target.value);
  };

  const onChangeClass = (value) => {
    setFormData({
      ...formData,
      class: value,
    });
  };
  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };
  const onChangeTerm = (value) => {
    setFormData({
      ...formData,
      term: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const classOptions = classes?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const termOptions = terms?.map((x) => ({
    value: x.id.toString(),
    label: x.termName,
  }));

  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));

  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  // Filter `option.label` match the user type `input`
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterClassOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  /**
   * @function getFeesDefaulters
   * @description get fee defaulters for a given class based on term and year
   */
  const getFeesDefaulters = () => {
    const onSuccess = (response) => {
      setDefaulters(response);
      toast.success(response.message);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(
        error.response ? error?.response?.data?.message : "Something went wrong"
      );
      setLoading(false);
    };
    let params = {
      term_id: formData.term || settings[0]?.currentTerm,
      year_id: formData.year || settings[0]?.currentYear,
      class_id: formData.class,
      percentage: formData.percentage,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}fee-defaulters`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  /**
   * @function getWholeSchoolFeesDefualter
   * @description get all the fees defaullter for the whole school based on the term and year
   */
  const getWholeSchoolFeesDefualter = () => {
    const onSuccess = (response) => {
      setSchoolFeesDefaulters(response);
      toast.success(response.message);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(
        error.response ? error?.response?.data?.message : "Something went wrong"
      );
      setLoading(false);
    };
    let params = {
      term_id: formData.term || settings[0]?.currentTerm,
      year_id: formData.year || settings[0]?.currentYear,
      percentage: formData.percentage,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}school-fees-defaulter`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 borer-grsy-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            Fee Defaulters Section
          </h1>
          <div className="flex justify-center">
            <h4 className="m-2 font-bold">
              Select the fees Defaulters Criteria
            </h4>
            <select
              onChange={handleCriteria}
              className=" border border-b-2 px-2 py-4 rounded-lg w-50"
            >
              <option value="">Select Fees defaulters</option>
              <option value="class">For Class</option>
              <option value="school"> For Whole School</option>
            </select>
          </div>
          <div className="flex ">
            {criteria !== "school" && (
              <div className="relative sm:w-1/2 md:w-full h-11 w-full min-w-[200px] mt-3 m-2">
                <label className="">Select Class</label>
                <div className="relative h-11 w-full min-w-[200px]">
                  <Select
                    showSearch
                    placeholder="Select Class"
                    optionFilterProp="children"
                    className="
                        h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                        "
                    onChange={onChangeClass}
                    onSearch={onSearch}
                    filterOption={filterClassOption}
                    size="large"
                    options={classOptions}
                  />
                </div>
              </div>
            )}
            <div className="relative h-11 w-full sm:w-1/2 md:w-full  min-w-[200px] mt-3 m-2">
              <label className="">Select Year</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select Year"
                  optionFilterProp="children"
                  className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
                  onChange={onChangeYear}
                  onSearch={onSearch}
                  filterOption={filterYearOption}
                  size="large"
                  options={yearOptions}
                />
              </div>
            </div>
            <div className="relative h-11 w-full sm:w-1/2 md:w-full  min-w-[200px] mt-3 m-2">
              <label className="">Select Term</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select Term"
                  optionFilterProp="children"
                  className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
                  onChange={onChangeTerm}
                  onSearch={onSearch}
                  filterOption={filterTermOption}
                  size="large"
                  options={termOptions}
                />
              </div>
            </div>
          </div>
          {/* percentge */}
          <div className="mb-4 relative h-11 w-full min-w-[200px] mt-6">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
              id="percentage"
              name="percentage"
              type="text"
              placeholder=" "
              value={formData.percentage}
              onChange={handleChange}
            />

            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Filter by Percentage
            </label>
          </div>
          {console.log(years)}
          <p></p>
          <h2 className="text-lg font-semibold mt-5 mb-2">
            Fee Defaulters Tables
          </h2>
          {criteria === "school" ? (
            <SchoolFeeDefaultersTable
              data={schoolFeeDefaulters.data}
              selectedTerm={terms?.filter(
                (x) =>
                  parseInt(x.id) ===
                  parseInt(formData.term || settings[0]?.currentTerm)
              )}
              selectedYear={years?.filter(
                (x) =>
                  parseInt(x.id) ===
                  parseInt(formData.year || settings[0]?.currentYear)
              )}
            />
          ) : (
            <DefaultersTables
              data={feesDefaulters.data}
              selectedClass={classes.filter(
                (x) => parseInt(x.id) === parseInt(formData.class)
              )}
              selectedTerm={terms?.filter(
                (x) =>
                  parseInt(x.id) ===
                  parseInt(formData.term || settings[0]?.currentTerm)
              )}
              selectedYear={years?.filter(
                (x) =>
                  parseInt(x.id) ===
                  parseInt(formData.year || settings[0]?.currentYear)
              )}
              threshold={formData.percentage}
            />
          )}

          <div className="flex justify-center">
            <button
              type="button"
              disabled={loading}
              className="px-6 m-2 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
              onClick={() => {
                if (criteria === "school") {
                  getWholeSchoolFeesDefualter();
                } else {
                  getFeesDefaulters();
                }
              }}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Generate defaulter list"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeeDefaulters;
