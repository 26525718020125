import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { Table, Drawer, Select, Divider, Modal, Input, Form } from "antd";
import Nav from "../../../nav";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

const { TextArea } = Input;

function ViewIncomes() {
  const { years } = useSelector((state) => state.years);
  const { settings } = useSelector((state) => state.settings.settings);
  const { terms } = useSelector((state) => state.terms);
  const [loading, setLoading] = useState(false);
  const [incomes, setIncomes] = useState([]);
  const [months, setMonths] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentIncome, setCurrentIncome] = useState(null);
  const [editFormData, setEditFormData] = useState({
    id: "",
    source: "",
    description: "",
    amount: "",
    month_id: "",
  });

  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    year: "",
    term: "",
    month: "",
  });

  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };

  const onChangeTerm = (value) => {
    setFormData({
      ...formData,
      term: value,
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleMonthChange = (value) => {
    setFormData({
      ...formData,
      month: value,
    });
  };

  const onClose = () => {
    setShowFilters(false);
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    setFormData({
      startDate: "",
      endDate: "",
      year: "",
      term: "",
      month: "",
    });
  };

  const openEditModal = (income) => {
    setCurrentIncome(income);
    setEditFormData({
      id: income.id,
      source: income.source,
      description: income.description,
      amount: income.amount,
      month_id: income.month_id,
    });
    setIsModalVisible(true);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditFormData({ ...editFormData, [name]: value });
  };

  const handleUpdateIncome = () => {
    const onSuccess = (response) => {
      toast.success("Income updated successfully");
      setIsModalVisible(false);
      getIncomes();
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}payroll/income/update`,
      "",
      editFormData,
      onSuccess,
      onError
    );
  };

  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));
  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const termOptions = terms?.map((term) => ({
    value: term.id.toString(),
    label: term.termName,
  }));
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterMonthOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const monthOptions = months?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));

  const columns = [
    { title: "Source", dataIndex: "source", key: "source", width: 120 },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 120,
    },
    {
      title: "Amount (UGX)",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
      width: 120,
    },
    {
      title: "Month",
      dataIndex: ["month", "name"],
      key: "month",
      width: 120,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        const date = new Date(text);
        return !isNaN(date.getTime()) ? format(date, "PPP p") : "Invalid Date";
      },
      width: 120,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <button
          onClick={() => openEditModal(record)}
          className="text-red-500 px-4 py-1 border border-red-500 rounded-md hover:text-white hover:bg-red-500"
        >
          Edit
        </button>
      ),
      width: 120,
    },
  ];

  const getIncomes = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setIncomes(response);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    let params = {
      start_date: formData.startDate,
      end_date: formData.endDate,
      year_id: formData.year || settings[0]?.currentYear,
      term_id: formData.term || settings[0]?.currentTerm,
      month_id: formData.month,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/incomes`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  const getMonths = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setMonths(response.months);
      setLoading(false);
    };
    const onError = () => {
      toast.error("Failed to retrieve months");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/months`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  useEffect(() => {
    getIncomes();
    getMonths();
  }, []);

  useEffect(() => {
    getIncomes();
  }, [formData]);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">View Incomes</h1>
          <div className="hidden lg:flex float-end">
            <button
              onClick={toggleFilters}
              className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Filters
            </button>
          </div>

          <Table
            columns={columns}
            dataSource={loading ? Array(10).fill({}) : incomes.incomes}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 1700,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row className="bg-gray-600 text-white font-bold">
                  <Table.Summary.Cell colSpan={2}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span>
                      {parseInt(incomes.total_amount).toLocaleString()}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={3}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            rowKey="id"
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Drawer
        title="Filters"
        placement="right"
        closable={true}
        onClose={onClose}
        open={showFilters}
        getContainer={false}
      >
        <div className="mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select Start Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-red-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder="Select a date"
              type="date"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select End Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-red-500 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
              placeholder="Select a date"
              type="date"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <Divider />
        <div className="mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select Year</label>
          <Select
            className="w-full"
            showSearch
            value={formData.year || settings[0]?.currentYear}
            placeholder="Select a year"
            optionFilterProp="children"
            onChange={onChangeYear}
            filterOption={filterYearOption}
            options={yearOptions}
          />
        </div>
        <Divider />
        <div className="mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select Term</label>
          <Select
            className="w-full"
            showSearch
            value={formData.term || settings[0]?.currentTerm}
            placeholder="Select a term"
            optionFilterProp="children"
            onChange={onChangeTerm}
            filterOption={filterTermOption}
            options={termOptions}
          />
        </div>
        <Divider />
        <div className="mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select Month</label>
          <Select
            className="w-full"
            showSearch
            placeholder="Select a month"
            optionFilterProp="children"
            onChange={handleMonthChange}
            filterOption={filterMonthOption}
            options={monthOptions}
          />
        </div>
        <Divider />
        <div className="flex gap-2">
          <button
            className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            onClick={resetFilters}
          >
            Reset
          </button>
        </div>
      </Drawer>

      <Modal
        title="Edit Income"
        visible={isModalVisible}
        onOk={handleUpdateIncome}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form layout="vertical">
          <Form.Item label="Source">
            <Input
              name="source"
              value={editFormData.source}
              onChange={handleEditChange}
            />
          </Form.Item>
          <Form.Item label="Description">
            <TextArea
              name="description"
              value={editFormData.description}
              onChange={handleEditChange}
            />
          </Form.Item>
          <Form.Item label="Amount (UGX)">
            <Input
              name="amount"
              value={editFormData.amount}
              onChange={handleEditChange}
            />
          </Form.Item>
          <Form.Item label="Month">
            <Select
              className="w-full"
              showSearch
              value={editFormData.month_id}
              placeholder="Select a month"
              optionFilterProp="children"
              onChange={(value) =>
                setEditFormData({ ...editFormData, month_id: value })
              }
              filterOption={filterMonthOption}
              options={monthOptions}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default ViewIncomes;
