import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Select } from "antd";
import { Modal } from "antd";
import JSPDF from "jspdf";
import "jspdf-autotable";
import Nav from "../../../nav";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import { useSelector } from "react-redux";

function StudentFinancialStatement() {
  const { settings } = useSelector((state) => state.settings.settings);
  const { terms } = useSelector((state) => state.terms);
  const { classes } = useSelector((state) => state.classes);
  const { years } = useSelector((state) => state.years);
  const [financialStatement, setFinancialStatement] = useState([]);
  const [classStudents, setClassStudents] = useState([]);
  const [, setYears] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTermPayments, setSelectedTermPayments] = useState([]);
  const [showButton,setShowButton] = useState(false);
  const {user} = useSelector((state) => state.auth);
  const [currentDate, setCurrentDate] = useState();
  const [formData, setFormData] = useState({
    reg_no: "",
    year: "",
    term: "",
    class: "",
  });
  const onSearch = (value) => {
    console.log("search:", value);
  };
  // Function to handle opening the modal and setting the selected term's payments
  const handleOpenModal = (term) => {
    setIsModalVisible(true);
    setSelectedTermPayments(financialStatement?.data[term]?.payments_made);
  };

  const onCancel = () => {
    setIsModalVisible(!isModalVisible);
  };

  const totalAmount = selectedTermPayments.reduce(
    (acc, curr) => acc + parseInt(curr.amount),
    0
  );

  const onChangeClass = (value) => {
    setFormData({
      ...formData,
      class: value,
    });
  };
  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };
  const onChangeClassStudent = (value) => {
    setFormData({
      ...formData,
      reg_no: value,
    });
  };
  const onChangeTerm = (value) => {
    setFormData({
      ...formData,
      term: value,
    });
  };

  const classOptions = classes?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const termOptions = terms?.map((x) => ({
    value: x.id.toString(),
    label: x.termName,
  }));

  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));
  const classStudentsOptions = classStudents?.map((student) => ({
    value: student.regno.toString(),
    label: student.regno + "--" + student.firstname + " " + student.surname,
  }));

  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  // Filter `option.label` match the user type `input`
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterClassOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const filterClassStudentOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const fetchAllYears = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setYears(response.years);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}get/years`, "", "", onSuccess, onError);
  };

  const fetchClassStudents = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setClassStudents(response.students);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("Failed to retreive Students");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/${formData.class}/classStudents`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const fetchStatement = () => {
    const onSuccess = (response) => {
      setFinancialStatement(response);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(
        error.response ? error?.response?.data?.message : "Something went wrong"
      );
      setLoading(false);
    };
    let params = {
      regNo: formData.reg_no,
      year_id: formData.year || settings[0]?.currentYear,
      class_id: formData.class,
    };

    if (formData.term !== "") {
      params = {
        ...params,
        term_id: formData.term,
      };
    }

    apiRequest(
      "get",
      `${API_BASE_URL}student-financial-history`,
      "",
      params,
      onSuccess,
      onError
    );
  };
  const getCurrentDate = () => {
    const onSuccess =(response)=>{
      setCurrentDate(response.current_date)
    }
    const onError =(error)=>{
      toast.error("failed to get curent date")
    }

    apiRequest(
      'get',
      `${API_BASE_URL}current-date`,
      "",
      "",
      onSuccess,
      onError
    )
  }

  const generatePDF = () => {
    const doc = new JSPDF();
    let student_name;
    doc.text(`Date Printed : ${currentDate}`, 15, 5 )
    doc.text(`Printed By : ${user?.data?.user?.name}`,15, 10 )
    Object.keys(financialStatement.data).forEach((term, index) => {
      const currentTerm = financialStatement.data[term];
      const previousTermBalance =
        index > 0
          ? financialStatement.data[
              Object.keys(financialStatement.data)[index - 1]
            ].balance_by_term
          : 0;

      const tableData = [
        ["Student Name", currentTerm.student_name],
        ["Student Reg No", currentTerm.regNo],
        ["Year", currentTerm.year],
        ["Term", currentTerm.term],
        ["Class", currentTerm.class],
        ["Stream", currentTerm.stream],
        [
          "Total Term Fees",
          `UGX ${parseInt(currentTerm.term_fees).toLocaleString()}`,
        ],
        [
          "Balance from Previous Term",
          `UGX ${parseInt(previousTermBalance).toLocaleString()}`,
        ],
        [
          "Total Fees to Pay",
          `UGX ${parseInt(currentTerm.total_fees_to_pay).toLocaleString()}`,
        ],
        [
          "Total Amount Paid",
          `UGX ${parseInt(currentTerm.total_paid_amounts).toLocaleString()}`,
        ],
        [
          "Fees Balance",
          `${currentTerm.balance_by_term > 0 ? `UGX ${parseInt(currentTerm.balance_by_term).toLocaleString()} Due` : `UGX ${parseInt(currentTerm.balance_by_term).toLocaleString()} Excess Paid`}`,
        ],
      ];

      if (term !== "term 1") {
        tableData.splice(5, 1); // Remove "Balance from Previous Term" row for all terms except the first one
      }
       // Title
    doc.setFontSize(18);
    doc.text(`Financial Statement for Student ${currentTerm.student_name} for Term ${currentTerm.term} `, 15, 20);
    student_name = currentTerm.student_name;

      doc.autoTable({
        head: [["Attribute", "Value"]],
        body: tableData,
        startY:  25
      });

      if (index < Object.keys(financialStatement.data).length - 1) {
        doc.addPage();
      }
    });

    doc.save(
      `Financial Statement for Student ${student_name}`
    );
  };

  useEffect(() => {
    fetchAllYears();
  }, []);
  useEffect(() => {
    if (formData.class !== "") {
      fetchClassStudents();
    }
  }, [formData.class]);
  useEffect(() => {
    getCurrentDate()
   
  }, []);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2  border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            View Financial Statement For Student
          </h1>
          <div className="flex">
            <div className="relative h-11 w-full min-w-[200px] mt-3 m-2">
              <label className="">Select Class</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select Class"
                  optionFilterProp="children"
                  className="
                        h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                        "
                  onChange={onChangeClass}
                  onSearch={onSearch}
                  filterOption={filterClassOption}
                  size="large"
                  options={classOptions}
                />
              </div>
            </div>
            <div className="relative h-11 w-full min-w-[200px] mt-3 m-2">
              <label className="">Select Year</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select Year"
                  optionFilterProp="children"
                  className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
                  onChange={onChangeYear}
                  onSearch={onSearch}
                  filterOption={filterYearOption}
                  size="large"
                  options={yearOptions}
                />
              </div>
            </div>
            <div className="relative h-11 w-full min-w-[200px] mt-3 m-2">
              <label className="">Select Term</label>
              <div className="relative h-11 w-full min-w-[200px]">
                <Select
                  showSearch
                  placeholder="Select Term"
                  optionFilterProp="children"
                  className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
                  onChange={onChangeTerm}
                  onSearch={onSearch}
                  filterOption={filterTermOption}
                  size="large"
                  options={termOptions}
                />
              </div>
            </div>
          </div>
          <div className="mb-4 mt-6 relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select or Search  Student"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
              onChange={onChangeClassStudent}
              onSearch={onSearch}
              filterOption={filterClassStudentOption}
              size="large"
              options={classStudentsOptions}
            />
          </div>
          <div className="flex justify-center">
            <button
              type="button"
              disabled={loading}
              className="px-6 m-2 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
              onClick={() => {
                fetchStatement();
                setShowButton(true);
              }}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Generate Statement(s)"
              )}
            </button>
          </div>

          <h2 className="text-lg font-semibold mb-2">Statement</h2>

          <div className="grid grid-cols-1 gap-4 md:grid-cols-3 mb-5">
            {financialStatement.data ? (
              Object.keys(financialStatement.data).map((term, index) => {
                const currentTerm = financialStatement.data[term];
                const previousTermBalance =
                  index > 0
                    ? financialStatement.data[
                        Object.keys(financialStatement.data)[index - 1]
                      ].balance_by_term
                    : 0;

                return (
                  <div
                    key={term}
                    className="bg-white rounded-lg shadow-md p-4 mb-4"
                  >
                    <h2 className="text-xl font-semibold mb-4">
                      TERM {currentTerm.term}
                    </h2>
                    <div>
                      <div className="relative overflow-x-auto">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 ">
                          <tbody>
                            <tr className="bg-white border-b ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Student Name
                              </th>
                              <td className="px-6 py-4 text-gray-950 font-bold">
                                {currentTerm.student_name}
                              </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Year
                              </th>
                              <td className="px-6 py-4 text-gray-950 font-bold">
                                {financialStatement.data[term].year}
                              </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Class
                              </th>
                              <td className="px-6 py-4 text-gray-950 font-bold">
                                {financialStatement.data[term].class}
                              </td>
                            </tr>
                            <tr className="bg-white border-b ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Stream
                              </th>
                              <td className="px-6 py-4 font-bold text-gray-950">
                                {financialStatement.data[term].stream}
                              </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Total Term Fees
                              </th>
                              <td className="px-6 py-4 text-gray-950 font-bold">
                                UGX{" "}
                                {parseInt(
                                  financialStatement.data[term].term_fees
                                ).toLocaleString()}{" "}
                              </td>
                            </tr>
                            {/* Other table rows */}
                            {term !== "term 1" && (
                              <tr className="bg-white border-b  ">
                                <th
                                  scope="row"
                                  className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                                >
                                  Balance from Previous Term
                                </th>
                                <td className="px-6 py-4 text-red-500 font-bold">
                                  UGX{" "}
                                  {parseInt(
                                    previousTermBalance
                                  ).toLocaleString()}
                                </td>
                              </tr>
                            )}
                            <tr className="bg-white border-b  ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Total Fees to Pay
                              </th>
                              <td className="px-6 py-4 text-gray-950 font-bold">
                                UGX{" "}
                                {parseInt(
                                  financialStatement.data[term]
                                    .total_fees_to_pay
                                ).toLocaleString()}{" "}
                              </td>
                            </tr>
                            <tr className="bg-white border-b  ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Total Amount Paid
                              </th>
                              <td className="px-6 py-4 text-green-500 font-bold">
                                UGX{" "}
                                {parseInt(
                                  financialStatement.data[term]
                                    .total_paid_amounts
                                ).toLocaleString()}{" "}
                              </td>
                            </tr>
                            <tr className="bg-white border-b ">
                              <th
                                scope="row"
                                className="px-6 py-4 font-medium text-blue-500 whitespace-nowrap "
                              >
                                Fees Balance
                              </th>
                              <td
                                className={` ${
                                  financialStatement.data[term]
                                    .balance_by_term > 0
                                    ? "text-red-600"
                                    : "text-green-500"
                                } px-6 py-4 font-bold`}
                              >
                                UGX{" "}
                                {financialStatement.data[term].balance_by_term >
                                0
                                  ? parseInt(
                                      financialStatement.data[term]
                                        .balance_by_term
                                    ).toLocaleString() +
                                    " " +
                                    "Due"
                                  : parseInt(
                                      financialStatement.data[term]
                                        .balance_by_term
                                    ) < 0 &&
                                    parseInt(
                                      financialStatement.data[term]
                                        .balance_by_term
                                    ) === 0
                                  ? financialStatement.data[term]
                                      .balance_by_term
                                  : financialStatement.data[term]
                                      .balance_by_term +
                                    " " +
                                    "excess paid"}{" "}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <span
                        onClick={() => handleOpenModal(term)}
                        className="button  border  border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white px-1 rounded-lg float-end py-2"
                      >
                        View Payments
                      </span>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center">
                No financial informtion Generated .... Select a student to see
                thier financial informtion{" "}
              </div>
            )}
          </div>
         {showButton && <span
            onClick={() => generatePDF()}
            className="button  border  m-2 mt-5 border-red-500 text-red-500 hover:bg-red-500 hover:text-white px-1 rounded-lg  py-2"
          >
            Download PDF
          </span>}
        </div>
      </div>
      <Modal
        title="Payments"
        width={900}
        open={isModalVisible}
        onCancel={onCancel}
        footer={null}
      >
        <div class="relative overflow-x-auto">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-white">
              <tr>
                <th scope="col" class="px-6 py-3">
                  Payment Date
                </th>
                <th scope="col" class="px-6 py-3">
                  Payer's Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Payer's Contact
                </th>
                <th scope="col" class="px-6 py-3">
                  Payment Method
                </th>
                <th>Amount (UGX)</th>
              </tr>
            </thead>
            <tbody>
              {selectedTermPayments.map((payment) => {
                return (
                  <tr className="bg-white border-b ">
                    <th
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap "
                    >
                      {payment.payment_date}
                    </th>
                    <td className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap">
                      {payment.guardian_name}
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap">
                      {payment.guardian_contact}
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap">
                      {payment?.payment_method?.name}
                    </td>
                    <td className="px-6 py-4 font-medium text-gray-500 whitespace-nowrap">
                      {parseInt(payment?.amount).toLocaleString()}
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
              </tr>
              <tr className="bg-gray-700">
                <td
                  className="px-6 py-4 font-medium text-white whitespace-nowrap"
                  colSpan={4}
                >
                  Total
                </td>
                <td className="px-6 py-4 font-medium text-white whitespace-nowrap">
                  {totalAmount.toLocaleString()}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
}

export default StudentFinancialStatement;
