import React, { useState } from "react";
import { Modal, Select } from "antd";
import axios from "axios";
import { API_BASE_URL } from "../../../common/constants";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

function ExpenseReport({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const { terms } = useSelector((state) => state.terms);
  const { years } = useSelector((state) => state.years);
  const [formData, setFormData] = useState({
    term: "",
    year: "",
  });

  const handleCancel = () => {
    setOpen(!open);
  };

  const handleChange = (value, name) => {
    setFormData({ ...formData, [name]: value });
  };

  const yearOptions = years?.map((year) => ({
    value: year.yearName.toString(),
    label: year.yearName,
  }));

  const termOptions = terms?.map((term) => ({
    value: term.id.toString(),
    label: term.termName,
  }));

  const handleSubmit = async () => {
    setLoading(true);

    let params = {
      year: formData.year,
      term_id: formData.term,
    };

    try {
      const response = await axios.get(
        `${API_BASE_URL}payroll/expense-report`,
        {
          params,
          responseType: "blob", // Important: Set the responseType to blob
        }
      );

      setLoading(false);
      const blob = new Blob([response.data], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `expense_report_${formData.year}_term_${formData.term}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      toast.success("Report downloaded successfully!");
    } catch (error) {
      setLoading(false);
      toast.error("Error occurred. Try again later.");
    }
  };

  return (
    <div>
      <Modal
        title="Expense Report"
        width={900}
        footer={[
          <button
            className="mt-6 mr-6 select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            type="button"
            disabled={loading}
            className="mt-6 mr-6 select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            onClick={handleSubmit}
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div>
            ) : (
              "Download Expense Report"
            )}
          </button>,
        ]}
        open={open}
        onCancel={handleCancel}
      >
        <div className="flex">
          <div className="relative h-11 w-full min-w-[200px] mt-9">
            <label className="">Select Academic Year</label>
            <div className="relative h-11 w-full min-w-[200px]">
              <Select
                showSearch
                placeholder="Select Academic Year"
                optionFilterProp="children"
                className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                onChange={(value) => handleChange(value, "year")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                options={yearOptions}
              />
            </div>
          </div>
          <div className="relative h-11 w-full min-w-[200px] mt-9">
            <label className="">Select Term</label>
            <div className="relative h-11 w-full min-w-[200px]">
              <Select
                showSearch
                placeholder="Select Term"
                optionFilterProp="children"
                className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                onChange={(value) => handleChange(value, "term")}
                filterOption={(input, option) =>
                  (option?.label ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                size="large"
                options={termOptions}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ExpenseReport;
