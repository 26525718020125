import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";

export const fetchYears = createAsyncThunk(
  "years/fetchYears",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { user } = getState().auth;
      const accessToken = user?.data?.access_token;
      if (!accessToken) {
        throw new Error("Access token not found");
      }

      const response = await axios.get(
        `${API_BASE_URL}get/years`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

const yearSlice = createSlice({
  name: "years",
  initialState: {
    years: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchYears.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchYears.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.years = action.payload.years;
      })
      .addCase(fetchYears.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || action.error.message;
      });
  },
});

export default yearSlice.reducer;
