import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";
export const fetchSettings = createAsyncThunk(
    "settings",
    async (_, { getState }) => {
      try {
        const { user } = getState().auth;
        const accessToken = user?.data?.access_token;
        if (!accessToken) {
          throw new Error("Access token not found");
        }
  
        const response = await axios.get(
          `${API_BASE_URL}settings`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  const settingsSlice = createSlice({
    name: "settings",
    initialState: {
      settings: [],
      status: "idle",
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchSettings.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchSettings.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.settings = action.payload;
        })
        .addCase(fetchSettings.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })
    },
  });
  
  export default settingsSlice.reducer;