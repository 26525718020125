import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Table, Drawer, Select, Divider, Skeleton } from "antd";
import { format } from 'date-fns';
import { useSelector } from "react-redux";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";
import ExpenseReport from "./ExpenseReport";

function ViewExpenses() {
  const { years } = useSelector((state) => state.years);
  const { settings } = useSelector((state) => state.settings.settings);
  const { terms } = useSelector((state) => state.terms);
  const [loading, setLoading] = useState(false);
  const [expenses, setExpenses] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [showExpenseReportModal, setShowExpenseReportModal] = useState(false);

  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    year: "",
    term: "",
  });

  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };
  const onChangeTerm = (value) => {
    setFormData({
      ...formData,
      term: value,
    });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    setFormData({
      startDate: "",
      endDate: "",
      year: "",
      term: "",
    });
  };

  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));
  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const termOptions = terms?.map((term) => ({
    value: term.id.toString(),
    label: term.termName,
  }));
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const handleModal = () => {
    setShowExpenseReportModal(true);
  };
  const getExpenses = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setExpenses(response);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };
    let params = {
      start_date: formData.startDate,
      end_date: formData.endDate,
      year_id: formData.year || settings[0]?.currentYear,
      term_id: formData.term || settings[0]?.currentTerm,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/expenses`,
      "",
      params,
      onSuccess,
      onError
    );
  };
  const columns = [
    {
      title: "description",
      dataIndex: "description",
      key: "description",
      width: 120,
    },
    {
      title: "Expense Category",
      dataIndex: ["expense_category", "name"],
      width: 120,
    },
    {
      title: "Expense subcategory",
      dataIndex: ["expense_sub_category", "name"],
      width: 120,
    },
    {
      title: "Amount (UGX)",
      dataIndex: "amount",
      key: "amount",
      width: 120,
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
    },
    {
      title: "Payment Date",
      dataIndex: "date",
      key: "date",
      width: 120,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      width:120,
      render: (text) => {
        const date = new Date(text);
        return !isNaN(date.getTime()) ? format(date, "PPP p") : "Invalid Date";
      },
    },
  ];

  useEffect(() => {
    getExpenses();
  }, []);
  useEffect(() => {
    getExpenses();
  }, [formData]);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            View Expenses{" "}
          </h1>
          <div className="hidden lg:flex float-end">
            <button
              onClick={toggleFilters}
              className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Filters
            </button>
            <button
              onClick={handleModal}
              className="float-end text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Expense Report
            </button>
          </div>

          <Table
            columns={columns}
            dataSource={loading ? Array(10).fill({}) : expenses.expenses}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 1700,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row className=" bg-gray-600 text-white font-bold">
                  <Table.Summary.Cell colSpan={3}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span>
                      {parseInt(expenses.total_amount).toLocaleString()}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={2}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            rowKey="id"
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Drawer
        title="Filters"
        placement="right"
        closable={true}
        onClose={onClose}
        open={showFilters}
        getContainer={false}
      >
        <div className=" mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select Start Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-8 relative h-11 w-full min-w-[200px] mt-6">
          <label className="">Select End Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="relative  h-11 w-full min-w-[200px] mt-9">
          <label className="">Filter By Year</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Year"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
              onChange={onChangeYear}
              filterOption={filterYearOption}
              size="large"
              options={yearOptions}
            />
          </div>
        </div>
        <div className="relative  h-11 w-full min-w-[200px] mt-9">
          <label className="">Filter By Term</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Term"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
              onChange={onChangeTerm}
              filterOption={filterTermOption}
              size="large"
              options={termOptions}
            />
          </div>
        </div>
        <Divider />
        <div className="flex justify-between mt-4">
          <button
            type="button"
            className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
            onClick={() => {
              setShowFilters(false);
            }}
          >
            Apply Filters
          </button>
          <button
            onClick={resetFilters}
            className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
          >
            Reset Filters
          </button>
        </div>
      </Drawer>
      <ExpenseReport
        open={showExpenseReportModal}
        setOpen={setShowExpenseReportModal}
      />
    </div>
  );
}

export default ViewExpenses;
