import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "antd";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

function AddAllowanceModal({ open, setOpen, setRefresh, refresh }) {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
      name: "",
      amount: "",
    });
    const [errorMessage, setErrorMessage] = useState({});
    const handleChange = (event) => {
      const { name, value } = event.target;
      setFormData({ ...formData, [name]: value });
      setErrorMessage({ ...errorMessage, [name]: null });
    };
  
    const validateForm = () => {
      const newErrors = {};
  
      if (!formData.name) {
        newErrors.name = "Name is required";
      }
      if (!formData.amount) {
        newErrors.amount = "amount is Required";
      }
      setErrorMessage(newErrors);
      return Object.keys(newErrors).length === 0;
    };
  
    const handleSubmit = () => {
      setLoading(true);
      if (!validateForm()) {
        return;
      }
      setLoading(true);
  
      let data = JSON.stringify({
        name: formData.name,
        amount: formData.amount,
      });
      const onSuccess = (response) => {
        toast.success(response.message);
        setFormData({
          name: "",
          amount: "",
        });
        setLoading(false);
        setRefresh(!refresh);
        setOpen(false)
      };
  
      const onError = (error) => {
        toast.error("failed");
        setLoading(false);
      };
  
      apiRequest(
        "post",
        `${API_BASE_URL}payroll/allowances`,
        data,
        "",
        onSuccess,
        onError
      );
    };
  const handleCancel = () => {
    setOpen(!open);
  };
  return (
    <div>
      <Modal
        title="Add New Salary Type"
        footer={[
          <button
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            type="button"
            disabled={loading}
            className="mt-6  mr-6   select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            onClick={handleSubmit}
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
            ) : (
              "Create Salary Structure"
            )}
          </button>,
        ]}
        open={open}
        onCancel={handleCancel}
      >
        <div className="max-w-2xl mx-auto">
          <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
          <h1 className=" text-lg justify-center items-center flex font-semibold mb-3">
                Enter details about the Allowance
              </h1>
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.name && "border-red-500"
                  }`}
                  id="name"
                  name="name"
                  type="text"
                  placeholder=" "
                  value={formData.name}
                  onChange={handleChange}
                />
                {errorMessage.name && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.name}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Name of Allowance Structure *
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px]">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.amount && "border-red-500"
                  }`}
                  id="amount"
                  name="amount"
                  type="number"
                  min="500"
                  step="500"
                  placeholder=" "
                  value={formData.amount}
                  onChange={handleChange}
                />
                {errorMessage.amount && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.amount}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Allowance Amount *
                </label>
              </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

AddAllowanceModal.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setRefresh: PropTypes.func,
  refresh:PropTypes.bool
};
export default AddAllowanceModal;
