import React, { useState, useEffect } from "react";
import { Modal, Tabs, Collapse, Table, Button, Select, Input } from "antd";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

function FeeStructureDetailModal({ open, setOpen, feeStructureId }) {
  const [feeStructureDetails, setFeeStructureDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [feeTypes, setFeeTypes] = useState([]);
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    fees: [],
  });

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTerm, setSelectedTerm] = useState(null);
  const [selectedFeeType, setSelectedFeeType] = useState("");
  const [amount, setAmount] = useState("");

  const handleCancel = () => {
    setOpen(false);
  };
  // const handleOk = () => {
  //   setLoading(!loading);
  // };

  /**
   * @var columns
   * @description columns for the table for students
   */
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <span>
          <span>
            <strong>{text}</strong>
          </span>
        </span>
      ),
    },
    {
      title: "RegNo",
      dataIndex: "regno",
      key: "regno",
      render: (text, record) => <strong>{text}</strong>,
    },
  ];

  /**
   * @var feesColumns
   * @description  columns for the table for fees
   */
  const feesColumns = [
    {
      title: "Fee Type",
      dataIndex: "feeType",
      key: "feeType",
      render: (text) => <strong className="text-lg">{text}</strong>,
    },
    {
      title: "Fee Amount (UGX)",
      dataIndex: "amount",
      key: "amount",
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
    },
  ];

  /**
   * @var  detailsColumns
   * @description columns for the table for details
   */
  const detailsColumns = [
    {
      title: "Attribute",
      dataIndex: "attribute",
      key: "attribute",
    },
    {
      title: "Value",
      dataIndex: "value",
      key: "value",
    },
  ];

  const studentsDataSource = feeStructureDetails?.students.map((student) => ({
    key: student.id,
    name: `${student.firstname} ${student.surname}`,
    regno: student.regno,
    // Add other properties as needed
  }));
  const detailsDataSource = [
    { key: "1", attribute: "Name", value: feeStructureDetails?.name || "-" },
    {
      key: "2",
      attribute: "Year",
      value: feeStructureDetails?.year?.yearName || "-",
    },
    {
      key: "4",
      attribute: "Class",
      value: feeStructureDetails?.classes?.name || "-",
    },
  ];

  const fetchDetails = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setFeeStructureDetails(response.fee_structure);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/fee-structure/${feeStructureId}`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  let term1Fees;
  let term2Fees;
  let term3Fees;
  let term1FeesDataSource;
  let term2FeesDataSource;
  let term3FeesDataSource;
  if (feeStructureDetails) {
    term1Fees = JSON.parse(feeStructureDetails.term_1_fees);
    term2Fees = JSON.parse(feeStructureDetails.term_2_fees);
    term3Fees = JSON.parse(feeStructureDetails.term_3_fees);

    // Map term1Fees to construct dataSource for the table
    term1FeesDataSource = term1Fees.map((fee, index) => {
      // Find the fee type name from feeTypes array based on fee_type_id
      const feeTypeName = feeTypes.find(
        (feeType) => parseInt(fee.fee_type_id) === parseInt(feeType.id)
      )?.name;

      return {
        key: index,
        feeType: feeTypeName || "Unknown",
        amount: fee.amount,
      };
    });
    term2FeesDataSource = term2Fees.map((fee, index) => {
      // Find the fee type name from feeTypes array based on fee_type_id
      const feeTypeName = feeTypes.find(
        (feeType) => parseInt(fee.fee_type_id) === parseInt(feeType.id)
      )?.name;

      return {
        key: index,
        feeType: feeTypeName || "Unknown",
        amount: fee.amount,
      };
    });
    term3FeesDataSource = term3Fees.map((fee, index) => {
      // Find the fee type name from feeTypes array based on fee_type_id
      const feeTypeName = feeTypes.find(
        (feeType) => parseInt(fee.fee_type_id) === parseInt(feeType.id)
      )?.name;

      return {
        key: index,
        feeType: feeTypeName || "Unknown",
        amount: fee.amount,
      };
    });
  }

  const fetchFeeTypes = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setFeeTypes(response.feeTypes);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/feeTypes`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  // Function to handle deletion of a fee record
  const handleDelete = (feeId, termId) => {
    // Filter out the deleted fee record from formData
    const updatedFees = formData.fees.filter(
      (fee) => fee.id !== feeId || fee.term_id !== termId
    );

    setFormData({ fees: updatedFees });
  };
  useEffect(() => {
    // Function to initialize formData with the initial fees data
    const initializeFormData = () => {
      const initialFees = [];

      // Function to add fees from each term to initialFees array
      const addFees = (fees, termId) => {
        if (fees) {
          fees.forEach((item) => {
            initialFees.push({
              id: item.fee_type_id,
              term_id: termId,
              amount: item.amount,
            });
          });
        }
      };

      // Add fees from each term
      addFees(term1Fees, 1);
      addFees(term2Fees, 2);
      addFees(term3Fees, 3);

      setFormData({ fees: initialFees });
    };

    initializeFormData();
  }, [open, edit]);

  const showModal = (termId) => {
    setSelectedTerm(termId);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (selectedFeeType && amount) {
      const newFee = {
        id: selectedFeeType,
        term_id: selectedTerm,
        amount: parseInt(amount),
      };

      setFormData((prevFormData) => ({
        fees: [...prevFormData.fees, newFee],
      }));

      setIsModalVisible(false);
    }
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const updateStructure = () => {
    setLoading(true);
    const onSuccess = () => {
      toast.success("Fees Struture Updated");
      setLoading(false);
      setEdit(false);
      setOpen(false);
    };
    const onError = () => {
      setLoading(false);
      toast.error("Error Occured ... Please Try Again");
    };

    let data = JSON.stringify({ fees: formData.fees });

    apiRequest(
      "post",
      `${API_BASE_URL}fees/update/${feeStructureId}`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const calculateTotalFees = (fees) => {
    return fees.reduce((total, fee) => total + parseInt(fee.amount), 0);
  };

  const renderPanelHeader = (term, feesDataSource) => {
    const totalFees = calculateTotalFees(feesDataSource);
    return (
      <span>
        {term} - Total:{" "}
        <strong className="text-blue-600">{totalFees.toLocaleString()} UGX</strong>
      </span>
    );
  };

  useEffect(() => {
    fetchFeeTypes();
  }, [open]);

  useEffect(() => {
    if (feeStructureId) {
      fetchDetails();
    }
  }, [feeStructureId, open]);

  return (
    <div>
      <Modal
        open={open}
        title={`Details for ${feeStructureDetails?.name}`}
        onCancel={handleCancel}
        width={1500}
        footer={[
          <button
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          edit === false && (
            <button
              className="mt-6  mr-6   select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
              key="edit"
              onClick={() => handleEdit()}
            >
              Edit
            </button>
          ),
          edit === true && (
            <button
              className="mt-6  mr-6   select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
              key="edit"
              onClick={() => updateStructure()}
            >
              {" "}
              Save changes
            </button>
          ),
        ]}
      >
        {feeStructureDetails && (
          <Tabs defaultActiveKey="1" centered>
            {/* Tab 1: Display basic information */}
            <TabPane tab={<span className="text-2xl">Details</span>} key="1">
              <Table
                dataSource={loading ? Array(10).fill({}) : detailsDataSource}
                columns={detailsColumns}
                pagination={{
                  pageSize: 50,
                }}
                scroll={{
                  y: 240,
                }}
              />
            </TabPane>

            {/* Tab 2: Display fees */}
            <TabPane tab={<span className="text-2xl">Fees</span>} key="2">
              {edit ? (
                <>
                  <div className="grid grid-cols-3 gap-6">
                    {/* Column 1 */}
                    <div className="flex flex-col">
                      <h2 className="text-2xl">Term 1</h2>
                      {[...term1Fees, ...formData.fees]
                        .filter((item) => item.term_id === 1)
                        .map((item, index) => (
                          <div
                            className="flex px-1 py-1 items-center border-b border-gray-200"
                            key={index}
                          >
                            <div className="flex-1">
                              <span className="font-semibold  text-xl">
                                {" "}
                                {
                                  feeTypes.find(
                                    (feeType) =>
                                      parseInt(item.id) === parseInt(feeType.id)
                                  )?.name
                                }{" "}
                                :
                              </span>
                              <span className="text-lg m-1 text-blue-500">
                                {parseInt(item.amount).toLocaleString()}
                              </span>
                            </div>
                            <button
                              className="ml-5 border-red-500 text-red-400 border hover:bg-red-500 rounded-lg hover:text-white px-4 py-1"
                              onClick={() =>
                                handleDelete(item.id, item.term_id)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      <button
                        className=" border-blue-500 text-blue-400 border mt-5 hover:bg-blue-500 rounded-lg hover:text-white px-4 py-1"
                        onClick={() => showModal(1)}
                      >
                        Add Fee
                      </button>
                    </div>
                    {/* Column 2 */}
                    <div className="flex flex-col">
                      <h2 className="text-2xl">Term 2</h2>
                      {[...term2Fees, ...formData.fees]
                        .filter((item) => item.term_id === 2)
                        .map((item, index) => (
                          <div
                            className="flex px-1 py-1 items-center border-b border-gray-200"
                            key={index}
                          >
                            <div className="flex-1">
                              <span className="font-semibold text-xl">
                                {" "}
                                {
                                  feeTypes.find(
                                    (feeType) =>
                                      parseInt(item.id) === parseInt(feeType.id)
                                  )?.name
                                }{" "}
                                :
                              </span>
                              <span className="text-lg m-1 text-blue-500">
                                {parseInt(item.amount).toLocaleString()}
                              </span>
                            </div>
                            <button
                              className="ml-5 border-red-500 text-red-400 border hover:bg-red-500 rounded-lg hover:text-white px-4 py-1"
                              onClick={() =>
                                handleDelete(item.id, item.term_id)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      <button
                        className=" border-blue-500 text-blue-400 mt-5 border hover:bg-blue-500 rounded-lg hover:text-white px-4 py-1"
                        onClick={() => showModal(2)}
                      >
                        Add Fee
                      </button>
                    </div>
                    {/* Column 3 */}
                    <div className="flex flex-col">
                      <h2 className="text-2xl">Term 3</h2>
                      {[...term3Fees, ...formData.fees]
                        .filter((item) => item.term_id === 3)
                        .map((item, index) => (
                          <div
                            className="flex px-1 py-1 items-center border-b border-gray-200"
                            key={index}
                          >
                            <div className="flex-1">
                              <span className="font-semibold text-xl">
                                {" "}
                                {
                                  feeTypes.find(
                                    (feeType) =>
                                      parseInt(item.id) === parseInt(feeType.id)
                                  )?.name
                                }{" "}
                                :
                              </span>
                              <span className="text-lg m-1 text-blue-500">
                                {parseInt(item.amount).toLocaleString()}
                              </span>
                            </div>
                            <button
                              className="ml-5 border-red-500 text-red-400 border hover:bg-red-500 rounded-lg hover:text-white px-4 py-1"
                              onClick={() =>
                                handleDelete(item.id, item.term_id)
                              }
                            >
                              Delete
                            </button>
                          </div>
                        ))}
                      <button
                        className=" border-blue-500 text-blue-400 border  mt-5 hover:bg-blue-500 rounded-lg hover:text-white px-4 py-1"
                        onClick={() => showModal(3)}
                      >
                        Add Fee
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <div>
                  <Collapse accordion size="large">
                    <Panel
                      header={renderPanelHeader(
                        "Term 1 Fees",
                        term1FeesDataSource
                      )}
                      key="1"
                    >
                      <ul>
                        <Table
                          dataSource={term1FeesDataSource}
                          columns={feesColumns}
                          pagination={{
                            pageSize: 5,
                          }}
                          scroll={{
                            y: 240,
                          }}
                        />
                      </ul>
                    </Panel>
                    <Panel
                      header={renderPanelHeader(
                        "Term 2 Fees",
                        term2FeesDataSource
                      )}
                      key="2"
                    >
                      <ul>
                        <Table
                          dataSource={term2FeesDataSource}
                          columns={feesColumns}
                          pagination={{
                            pageSize: 5,
                          }}
                          scroll={{
                            y: 240,
                          }}
                        />
                      </ul>
                    </Panel>
                    <Panel
                      header={renderPanelHeader("Term 3", term3FeesDataSource)}
                      key="3"
                    >
                      <ul>
                        <Table
                          dataSource={term3FeesDataSource}
                          columns={feesColumns}
                          pagination={{
                            pageSize: 5,
                          }}
                          scroll={{
                            y: 240,
                          }}
                        />
                      </ul>
                    </Panel>
                  </Collapse>
                </div>
              )}
            </TabPane>
            <TabPane
              tab={
                <span className="text-2xl">
                  Students ({studentsDataSource.length})
                </span>
              }
              key="3"
            >
              <div>
                <Table
                  dataSource={studentsDataSource}
                  columns={columns}
                  pagination={{
                    pageSize: 50,
                  }}
                  scroll={{
                    y: 240,
                  }}
                />
              </div>
            </TabPane>
          </Tabs>
        )}
      </Modal>

      <Modal
        title="Add Fee"
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleModalCancel}
      >
        <Select
          style={{ width: "100%" }}
          placeholder="Select Fee Type"
          value={selectedFeeType}
          onChange={(value) => setSelectedFeeType(value)}
        >
          {feeTypes.map((feeType) => (
            <Option key={feeType.id} value={feeType.id}>
              {feeType.name}
            </Option>
          ))}
        </Select>
        <Input
          style={{ margin: "10px 0" }}
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </Modal>
    </div>
  );
}

FeeStructureDetailModal.propTypes = {
  open: PropTypes.bool,
  feeStructureId: PropTypes.number,
  setOpen: PropTypes.func,
};

export default FeeStructureDetailModal;
