import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Divider, Select } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";
import AddSalaryModal from "./AddSalaryModal";
import AddAllowanceModal from "./AddAllowanceModal";

function AssignSalary() {
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const [salarytypes, setSalaryTypes] = useState([]);
  const [allowances, setAllowances] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openSalaryModal, setOpenSalaryModal] = useState(false);
  const [openAllowanceModal, setOpenAllowanceModal] = useState(false);

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    staff_id: "",
    salary_type_id: "",
    allowance_ids: [],
  });
  const handleCheckboxChange = (e) => {
    const allowanceId = parseInt(e.target.value);
    const isChecked = e.target.checked;

    if (isChecked) {
      // If checkbox is checked, add allowance id to formData
      setFormData({
        ...formData,
        allowance_ids: [...formData.allowance_ids, allowanceId],
      });
    } else {
      // If checkbox is unchecked, remove allowance id from formData
      setFormData({
        ...formData,
        allowance_ids: formData.allowance_ids.filter(
          (id) => id !== allowanceId
        ),
      });
    }
  };
  // const handleChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormData({ ...formData, [name]: value }); // Update the corresponding field
  //   setErrorMessage({ ...errorMessage, [name]: null });
  // };
  const onChangeStaff = (value) => {
    setFormData({
      ...formData,
      staff_id: value,
    });
  };
  const handleNextStep = () => {
    // Validate step 1 fields before proceeding to step 2
    if (formData.staff_id && formData.salary_type_id) {
      setStep(2);
    } else {
      toast.error("Please fill in staff and salary type to continue.");
    }
  };
  const handleBack = () => {
    setStep(step - 1);
  };
  const filterStaffOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const staffOptions = staff?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));

  const onChangeSalary = (value) => {
    setFormData({
      ...formData,
      salary_type_id: value,
    });
  };

  const filterSalaryOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const salaryOptions = salarytypes?.map((x) => ({
    value: x.id.toString(),
    label: x.name + " " + parseInt(x.amount).toLocaleString(),
  }));

  const handleSalaryModal = () => {
    setOpenSalaryModal(true);
  };
  const handleAllowanceModal = () => {
    setOpenAllowanceModal(true);
  };

  const getStaff = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setStaff(response.staff);
      // toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/staff`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getSalaryType = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setSalaryTypes(response.salary_types);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("failed to get salarytypes");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/salary-types`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const getAllowances = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setAllowances(response.allowances);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("failed to get allowances");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/allowances`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const assignSalary = () => {
    const onSuccess = (response) => {
      toast.success(response.message);
      setLoading(false);
      setFormData({
        salary_type_id:""
      })
    };

    const onError = (error) => {
      console.log(error)
      toast.error("failed to get salarytypes");
      setLoading(false);
    };
    let data = {
      staff_id: formData.staff_id,
      salary_type_id: formData.salary_type_id,
    };

    apiRequest(
      "post",
      `${API_BASE_URL}payroll/assign-salary`,
      data,
      "",
      onSuccess,
      onError
    );
  };
  const assignAllowance = () => {
    const onSuccess = (response) => {
      toast.success(response.message);
      setLoading(false);
      setFormData({
        allowance_ids:[]
      });
      setStep(1);
    };

    const onError = (error) => {
      console.log(error)
      toast.error("failed to get salarytypes");
      setLoading(false);
    };
    let data = {
      staff_id: formData.staff_id,
      allowance_ids: formData.allowance_ids,
    };

    apiRequest(
      "post",
      `${API_BASE_URL}payroll/assign-allowances`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const handleSubmit = () => {
    if (formData.salary_type_id && formData.staff_id) {
      assignSalary();
    } else {
      toast.error("Please fill in staff and salary type to continue.");
    }

    if (formData.allowance_ids.length > 0) {
      assignAllowance();
    } else {
      toast.error("Please select at least one allowance.");
    }
  };

  useEffect(() => {
    getStaff();
    getSalaryType();
    getAllowances();
  }, []);
  useEffect(() => {
    getSalaryType();
    getAllowances();
  }, [refresh]);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-14">
          <h1 className="text-2xl justify-center items-center">
            Assign Salaries $ Allowances to Staff
          </h1>
          <div className="mx-auto max-w-2xl">
            <form className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
              <h1 className=" text-lg justify-center items-center flex font-semibold mb-3">
                Assign a Staff memeber Salary $ Allowances
              </h1>
              <Divider />
              {step === 1 && (
                <>
                  {" "}
                  <div className="relative h-11 w-full min-w-[200px] mt-3">
                    <label className="">Select Staff Member</label>
                    <div className="relative h-11 w-full min-w-[200px]">
                      <Select
                        showSearch
                        placeholder="Select Staff Member"
                        optionFilterProp="children"
                        className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                        onChange={onChangeStaff}
                        filterOption={filterStaffOption}
                        size="large"
                        options={staffOptions}
                      />
                    </div>
                  </div>
                  <div className="relative h-11 w-full min-w-[200px] mt-5">
                    <label className="">Select Salary Type</label>
                    <div className="relative h-11 w-full min-w-[200px]">
                      <Select
                        showSearch
                        placeholder="Select Salary"
                        optionFilterProp="children"
                        className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                        onChange={onChangeSalary}
                        filterOption={filterSalaryOption}
                        size="large"
                        options={salaryOptions}
                      />
                    </div>
                  </div>
                  <div className="mt-9 font-thin ">
                    <span>Cannot find salary type ? </span>
                    <button
                      onClick={handleSalaryModal}
                      className="border rounded-lg border-red-400 text-red-400 hover:bg-red-400 hover:text-white px-2 py-1"
                    >
                      Add New Salary Type
                    </button>
                  </div>{" "}
                </>
              )}
              {step === 2 && (
                <>
                  {allowances.map((allowance) => (
                    <div key={allowance.id}>
                      <input
                        type="checkbox"
                        id={`allowance-${allowance.id}`}
                        value={allowance.id}
                        onChange={handleCheckboxChange}
                        checked={formData?.allowance_ids?.includes(allowance.id)}
                      />{" "}
                      <label htmlFor={`allowance-${allowance.id}`}>
                        {allowance.name}{" "}
                        <span className="text-red-400 m-3">
                          UGX {parseInt(allowance.amount).toLocaleString()}
                        </span>
                      </label>
                    </div>
                  ))}

                  <div className="mt-9 font-thin ">
                    <span>Cannot find allowance ? </span>
                    <button
                      onClick={handleAllowanceModal}
                      className="border rounded-lg border-red-400 text-red-400 hover:bg-red-400 hover:text-white px-2 py-1"
                    >
                      Add New Allowance
                    </button>
                  </div>
                </>
              )}
            </form>
            {step === 1 && (
              <div>
                <button
                  onClick={handleNextStep}
                  className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                >
                  Next
                </button>
              </div>
            )}
            {step === 2 && (
              <div className=" justify-between">
                <button
                  onClick={handleBack}
                  className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                >
                  Prevoius
                </button>
                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  className="mt-6  mr-6   select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
                >
                  { loading ? "Submitting .... " : "Submit"}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <AddSalaryModal
        open={openSalaryModal}
        setRefresh={setRefresh}
        refresh={refresh}
        setOpen={setOpenSalaryModal}
      />
      <AddAllowanceModal
        open={openAllowanceModal}
        setRefresh={setRefresh}
        refresh={refresh}
        setOpen={setOpenAllowanceModal}
      />
    </div>
  );
}

export default AssignSalary;
