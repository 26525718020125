import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";
export const fetchMonths = createAsyncThunk(
    "years",
    async (_, { getState }) => {
      try {
        const { user } = getState().auth;
        const accessToken = user?.data?.access_token;
        if (!accessToken) {
          throw new Error("Access token not found");
        }
  
        const response = await axios.get(
          `${API_BASE_URL}payroll/months`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  const monthSlice = createSlice({
    name: "months",
    initialState: {
      months: [],
      status: "idle",
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchMonths.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchMonths.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.months = action.payload.months;
        })
        .addCase(fetchMonths.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })
    },
  });
  
  export default monthSlice.reducer;