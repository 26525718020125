import React, { useState } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Modal } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

function PaymentAmountUpdateModal({ paymentId, open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    reason: "",
    amount: "",
  });

  const [errorMessage, setErrorMessage] = useState({});
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value }); // Update the corresponding field
    setErrorMessage({ ...errorMessage, [name]: null });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.reason) {
      newErrors.reason = "Reason is required";
    }
    if (!formData.amount) {
      newErrors.amount = "Amount is Required";
    }

    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const updatePayment = () => {
    setLoading(true);
    if (!validateForm()) {
      toast.error(errorMessage.fees);
    }
    setLoading(true);

    const onSuccess = (response) => {
        toast.success(response.message);
        setOpen(false);
        setFormData({
          reason: "",
          amount: "",
        })
    };
    const onError = (error) => {
        toast.error(error?.response?.data?.message);
    };
    let data = JSON.stringify(formData);
    apiRequest(
      "post",
      `${API_BASE_URL}payments/${paymentId}/edit-amount`,
      data,
      "",
      onSuccess,
      onError
    );
  };
  return (
    <div>
      <Modal
        open={open}
        title="Update Payment Amount"
        width={700}
        onCancel={handleCancel}
        footer={[
          <button
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,

          <button
            className="mt-6  mr-6   select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="edit"
            onClick={() => updatePayment()}
            disabled={loading}
          >
            {" "}
            Save changes
          </button>,
        ]}
      >
        <div className="mb-4 relative h-11 w-full min-w-[200px]">
          <input
            className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
              errorMessage.amount && "border-red-500"
            }`}
            id="amount"
            name="amount"
            type="text"
            placeholder=" "
            value={formData.amount}
            onChange={handleChange}
          />
          {errorMessage.amount && (
            <span className="text-xs text-red-500  flex justify-center ">
              {errorMessage.amount}
            </span>
          )}
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            New Amount *
          </label>
        </div>
        <div className="mb-4 relative h-11 w-full min-w-[200px]">
          <textarea
            className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
              errorMessage.reason && "border-red-500"
            }`}
            id="reason"
            name="reason"
            type="text"
            rows={5}
            placeholder=" "
            value={formData.reason}
            onChange={handleChange}
          />
          {errorMessage.reason && (
            <span className="text-xs text-red-500  flex justify-center ">
              {errorMessage.reason}
            </span>
          )}
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Reason For Change *
          </label>
        </div>
      </Modal>
    </div>
  );
}

PaymentAmountUpdateModal.propTypes = {
  open: PropTypes.bool,
  paymentId: PropTypes.number,
  setOpen: PropTypes.func,
};
export default PaymentAmountUpdateModal;
