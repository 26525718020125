import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import JSPDF from "jspdf";
import "jspdf-autotable";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

const SchoolFeeDefaultersTable = ({ data,selectedTerm,
  selectedYear, }) => {
  const [loading, setLoading] = useState(false);
  const [withPayments, setWithPayments] = useState(0);
  const {user} = useSelector((state) => state.auth);
  const [currentDate, setCurrentDate] = useState();
  // Define columns for the table
  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Registration Number",
      dataIndex: "regNO",
      key: "regNO",
    },
    {
      title: "Class",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Stream",
      dataIndex: "stream",
      key: "stream",
    },
    {
      title: "Percentage Paid",
      dataIndex: "percentage_paid",
      key: "percentage_paid",
      render: (percentage) => (
        <span
          style={{
            color: percentage < 100 && percentage < 50 ? "red" : "blue",
          }}
        >
          {percentage.toFixed(2)}%
        </span>
      ),
    },
    {
      title: "Pending Fees",
      dataIndex: "pending_fees",
      key: "pending_fees",
      render: (text) => (
        <span style={{ color: text > 0 ? "red" : "inherit" }}>{text?.toLocaleString()}</span>
      ),
    },
  ];

  // Calculate total amounts for defaulters
  const calculateTotalAmounts = (defaultersData) => {
    let totalFeesBalance = 0;
    defaultersData?.forEach((defaulter) => {
      totalFeesBalance += parseFloat(defaulter.pending_fees);
    });
    return totalFeesBalance;
  };

  
  // Display total amounts on frontend
  const renderTotalAmount = (totalAmount) => (
    <div className="font-bold text-lg text-green-500">Total Fees Balance: UGX {totalAmount?.toLocaleString()}</div>
  );

  // Include total amounts in PDF table footer
  const tableFooter = (totalAmount) => [
    ["Total", "", "", "","","", totalAmount.toLocaleString()],
  ];

  const getCurrentDate = () => {
    const onSuccess =(response)=>{
      setCurrentDate(response.current_date)
    }
    const onError =(error)=>{
      toast.error("failed to get curent date")
    }

    apiRequest(
      'get',
      `${API_BASE_URL}current-date`,
      "",
      "",
      onSuccess,
      onError
    )
  }

  // Transform the data into a format suitable for Ant Design Table
  const transformedData =
    data &&
    Object.entries(data).reduce((acc, [grade, students]) => {
      Object.entries(students).forEach(([category, defaulterList]) => {
        defaulterList.forEach((student, index) => {
          acc.push({
            key: student.student_id,
            no: index + 1,
            grade,
            category,
            ...student,
          });
        });
      });
      return acc;
    }, []);

    const totalDefaultersAmount = data&& calculateTotalAmounts(transformedData);

  /**
   *
   */
  const handleDownLoadPDF = () => {
    setLoading(true);
    const head = [
      ["No", "Student Name", "Reg No", "Class", "Stream"]
    ];
  
    if (withPayments === 1) {
      head[0].push("Payment Percentage (%)", "Fees Balance (UGX)");
    }
    const doc = new JSPDF("landscape");
    doc.text(`Pdf Showing All Fee Defaulters for term ${selectedTerm[0]?.termName}`, 10, 10);
    doc.text(`Date Printed : ${currentDate}`, 15, 20 ).setFontSize(10);
    doc.text(`Printed By : ${user?.data?.user?.name}`,15, 30 ).setFontSize(10);

    doc.autoTable({
      startY: 50,
      head: head,
      body: transformedData.map((student, index) => {
        const row = [
          index + 1,
          student?.student_name,
          student?.regNO,
          student?.class,
          student.stream
        ];
  
        if (withPayments === 1) {
          row.push(
            parseFloat(student?.percentage_paid).toFixed(2),
            parseInt(student?.pending_fees).toLocaleString()
          );
        }
  
        return row;
      }),
      foot: withPayments === 1  && tableFooter(totalDefaultersAmount)
    });
    doc.save(
      `All_defaulters_Term_${selectedTerm[0]?.termName}+${selectedYear[0]?.yearName}.pdf`
    );
    setLoading(false);
    setWithPayments(0);

  };

  useEffect(() => {
    getCurrentDate()
   
  }, []);

  return (
    <div>
      <h2>Fee Defaulters</h2>
      <Table columns={columns} dataSource={transformedData} />
      {renderTotalAmount(totalDefaultersAmount)}
      <div > <input type="checkbox" onChange={()=> {setWithPayments(1)}}  /> {" "} <label className="font-semibold text-xl">Download With Fees Balance</label> </div>
          <div>
            <button
              type="button"
              disabled={loading}
              className="px-6 m-2 py-3 bg-red-500 text-white rounded-md hover:bg-red-600"
              onClick={() => {
                handleDownLoadPDF();
              }}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Download PDF"
              )}
            </button>
          </div>
    </div>
  );
};

SchoolFeeDefaultersTable.protoTypes = {
  data: PropTypes.object,
  threshold: PropTypes.number,
  selectedTerm: PropTypes.array,
  selectedYear: PropTypes.array,
}

export default SchoolFeeDefaultersTable;
