import React, { useState } from "react";
import Nav from "../../../nav";
import { toast, ToastContainer } from "react-toastify";
import { Select } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

function AddAsset() {
  const { Option } = Select;
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    purchase_price: "",
    purchase_date: "",
    can_define_depreciation_percentage: false,
    depreciation_percentage: "",
    depreciation_frequency: "",
    current_price: "",
  });
  const [errorMessage, setErrorMessage] = useState({});
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const handleSelectChange = (value, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const validateForm = () => {
    const newErrors = {};

    if (!formData.name) {
      newErrors.name = "Name  is required";
    }

    if (!formData.purchase_price) {
      newErrors.purchase_price = "purchase price source is Required";
    }
    if (!formData.purchase_date) {
      newErrors.purchase_date = "purchase date is required";
    }
    // Check for depreciation details if the checkbox is checked
    if (formData.can_define_depreciation_percentage === true) {
      if (!formData.depreciation_percentage) {
        newErrors.depreciation_percentage =
          "Depreciation percentage is required";
      }
      if (!formData.depreciation_frequency) {
        newErrors.depreciation_frequency = "Depreciation frequency is required";
      }
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); 
    
    if (!validateForm()) {
      return;
    }
    setLoading(true);
    const onSuccess = (response) => {
      toast.success(response.message);
      setFormData({
        name: "",
        purchase_price: "",
        purchase_date: "",
        can_define_depreciation_percentage: false,
        depreciation_percentage: "",
        depreciation_frequency: "",
        current_price: "",
      });
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(
        error.response?.data?.message ||
          "An error occurred while registering the asset"
      );
      setLoading(false);
    };

    apiRequest(
      "post",
      `${API_BASE_URL}assets/addAssets`,
      JSON.stringify(formData),
      "",
      onSuccess,
      onError
    );
  };

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1>Add Asset</h1>
          <div className="max-w-2xl mx-auto">
            <form className="bg-white shadow-sm rounded px-8 pt-6 pb-8 mb-4">
              <h1 className="text-lg justify-center items-center flex font-semibold mb-3">
                Enter Asset Details
              </h1>

              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.name && "border-red-500"
                  }`}
                  id="name"
                  name="name"
                  type="text"
                  placeholder=" "
                  value={formData.name}
                  onChange={handleChange}
                />
                {errorMessage.name && (
                  <span className="text-xs text-red-500 flex justify-center">
                    {errorMessage.name}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Asset Name *
                </label>
              </div>

              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.purchase_price && "border-red-500"
                  }`}
                  id="purchase_price"
                  name="purchase_price"
                  type="number"
                  placeholder=" "
                  value={formData.purchase_price}
                  onChange={handleChange}
                />
                {errorMessage.purchase_price && (
                  <span className="text-xs text-red-500 flex justify-center">
                    {errorMessage.purchase_price}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Purchase Price *
                </label>
              </div>

              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.purchase_date && "border-red-500"
                  }`}
                  id="purchase_date"
                  name="purchase_date"
                  type="date"
                  placeholder=" "
                  value={formData.purchase_date}
                  onChange={handleChange}
                />
                {errorMessage.purchase_date && (
                  <span className="text-xs text-red-500 flex justify-center">
                    {errorMessage.purchase_date}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Purchase Date *
                </label>
              </div>

              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <label className="inline-flex items-center mt-3">
                  <input
                    type="checkbox"
                    className="form-checkbox h-5 w-5 text-green-600"
                    name="can_define_depreciation_percentage"
                    checked={formData.can_define_depreciation_percentage}
                    onChange={handleChange}
                  />
                  <span className="ml-2 text-gray-700">
                    Can Define Depreciation Percentage?
                  </span>
                </label>
              </div>

              {formData.can_define_depreciation_percentage && (
                <>
                  <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                    <input
                      className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                        errorMessage.depreciation_percentage && "border-red-500"
                      }`}
                      id="depreciation_percentage"
                      name="depreciation_percentage"
                      type="number"
                      placeholder=" "
                      value={formData.depreciation_percentage}
                      onChange={handleChange}
                    />
                    {errorMessage.depreciation_percentage && (
                      <span className="text-xs text-red-500 flex justify-center">
                        {errorMessage.depreciation_percentage}
                      </span>
                    )}
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Depreciation Percentage
                    </label>
                  </div>

                  <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                    <Select
                      placeholder="Select depreciation frequency"
                      value={formData.depreciation_frequency}
                      onChange={(value) =>
                        handleSelectChange(value, "depreciation_frequency")
                      }
                      className={`w-full rounded-md ${
                        errorMessage.depreciation_frequency
                          ? "border-red-500"
                          : "border-blue-gray-200"
                      }`}
                    >
                      <Option value="">Select Depreciation Frequency</Option>
                      <Option value="monthly">Monthly</Option>
                      <Option value="yearly">Yearly</Option>
                    </Select>
                    {errorMessage.depreciation_frequency && (
                      <span className="text-xs text-red-500 flex justify-center">
                        {errorMessage.depreciation_frequency}
                      </span>
                    )}
                    {/* <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Depreciation Frequency
                    </label> */}
                  </div>
                </>
              )}

             {!formData.can_define_depreciation_percentage && <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.current_price && "border-red-500"
                  }`}
                  id="current_price"
                  name="current_price"
                  type="number"
                  placeholder=" "
                  value={formData.current_price}
                  onChange={handleChange}
                />
                {errorMessage.current_price && (
                  <span className="text-xs text-red-500 flex justify-center">
                    {errorMessage.current_price}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Current Price
                </label>
              </div>}

              <div className="flex justify-center">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  disabled={loading}
                  className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  {loading ? (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                  ) : (
                    "Register Asset"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AddAsset;
