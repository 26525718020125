import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";

function ExpenseSubCatModal({ open, setOpen }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [expenseCategories, setExpenseCategories] = useState([]);
  const [formData, setFormData] = useState({
    parent_cat: "",
    name: "",
    description: "",
  });

  const handleCancel = () => {
    setOpen(!open);
  };
  const fetchExpenseCategories = () => {
    const onSuccess = (response) => {
      setExpenseCategories(response.expense_categories);
    };
    const onError = (error) => {
      toast.error("failed to retrieve expense categories");
    };
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/expense-categories`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const filterExpenseCatOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const expenseCatOptions = expenseCategories?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const onChangeExpenseCat = (value) => {
    setFormData({
      ...formData,
      parent_cat: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.amount) {
      newErrors.amount = "Amount paid is required";
    }

    if (!formData.term_id) {
      newErrors.term_id = "School Term is Required";
    }
    if (!formData.description) {
      newErrors.description = "Expense Description is Required";
    }
    if (!formData.date) {
      newErrors.date = "Date is Required";
    }

    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm) {
      return;
    }
    setLoading(true);
    const onSuccess = (response) => {
      toast.success(response.message);
      setFormData({
        name: "",
        description: "",
        parent_cat: "",
      });
      setLoading(false);
    };
    const onError = (error) => {
      toast.error('Error Occured');
      setLoading(false);
    };
    let data = {
      name: formData.name,
      description: formData.description,
      parent_cat: formData.parent_cat,
    };

    apiRequest(
      "post",
      `${API_BASE_URL}payroll/expense-subcategories`,
      data,
      "",
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    fetchExpenseCategories();
  }, []);

  return (
    <div>
      <Nav />{" "}
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1>Add Expense Category </h1>
          <div className="max-w-2xl mx-auto">
            <form className="bg-white shadow-sm rounded px-8 pt-6 pb-8 mb-4">
              <h1 className=" text-lg justify-center items-center flex font-semibold mb-3">
                Enter Expense Category details
              </h1>
              <div className=" mb-7 relative h-11 w-full min-w-[200px] mt-3">
                <label className="">Select Expense Category</label>
                <div className="relative h-11 w-full min-w-[200px]">
                  <Select
                    showSearch
                    placeholder="Select Expense Category"
                    optionFilterProp="children"
                    className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                    onChange={onChangeExpenseCat}
                    filterOption={filterExpenseCatOption}
                    size="large"
                    options={expenseCatOptions}
                  />
                </div>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.name && "border-red-500"
                  }`}
                  id="name"
                  name="name"
                  type="text"
                  placeholder=" "
                  value={formData.name}
                  onChange={handleChange}
                />
                {errorMessage.name && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.name}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Name *
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
                  id="description"
                  name="description"
                  type="text"
                  placeholder=" "
                  value={formData.description}
                  onChange={handleChange}
                />

                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Description *
                </label>
              </div>

              <div className="flex justify-center">
                <button
                  type="button"
                  disabled={loading}
                  className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                  ) : (
                    "Add Expense Category"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ExpenseSubCatModal;
