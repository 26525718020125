import React, { useEffect, useState } from "react";
import { Table } from "antd";
import PropTypes from "prop-types";
import JSPDF from 'jspdf';
import 'jspdf-autotable';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

const DefaultersTables = ({
  data,
  threshold,
  selectedClass,
  selectedTerm,
  selectedYear,
}) => {

  const {user} = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [withPayments, setWithPayments] = useState(0);
  const [currentDate, setCurrentDate] = useState();
  // Determine the key for below and above threshold based on user input
  const belowThresholdKey = `below_${threshold}_percent`;
  const aboveThresholdKey = `above_${threshold}_percent`;
  const defaultKey = "default";
  // Extract data based on the determined keys
  const belowThresholdData =
    threshold && data && (data[belowThresholdKey] || []);
  const aboveThresholdData =
    threshold && data && (data[aboveThresholdKey] || []);
  const defaultData = data && (data[defaultKey] || []);

  // Calculate total amounts for defaulters
  const calculateTotalAmounts = (defaultersData) => {
    let totalFeesBalance = 0;
    defaultersData?.forEach((defaulter) => {
      totalFeesBalance += parseFloat(defaulter.pending_fees.pendingFees);
    });
    return totalFeesBalance;
  };

  const totalDefaultersAmount = defaultData&& calculateTotalAmounts(defaultData);
  // const totalBelowThresholdAmount = calculateTotalAmounts(belowThresholdData);
  // const totalAboveThresholdAmount = calculateTotalAmounts(aboveThresholdData);

  // Display total amounts on frontend
  const renderTotalAmount = (totalAmount) => (
    <div className="font-bold text-lg text-green-500">Total Fees Balance: UGX {totalAmount.toLocaleString()}</div>
  );

  // Include total amounts in PDF table footer
  const tableFooter = (totalAmount) => [
    ["Total", "", "", "","", totalAmount.toLocaleString()],
  ];

  const getCurrentDate = () => {
    const onSuccess =(response)=>{
      setCurrentDate(response.current_date)
    }
    const onError =(error)=>{
      toast.error("failed to get curent date")
    }

    apiRequest(
      'get',
      `${API_BASE_URL}current-date`,
      "",
      "",
      onSuccess,
      onError
    )
  }

  // Define columns for the tables
  const columns = [
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Reg No.",
      dataIndex: "regNO",
      key: "regNO",
    },
    {
      title: "Stream",
      dataIndex: "stream",
      key: "stream",
    },
    {
      title: " Payment Percentage  (%)",
      dataIndex: ["pending_fees", "percentagePaid"],
      key: "percentage_paid",
      render: (percentagePaid) => (
        <span className="text-red-500">
          {" "}
          {parseFloat(percentagePaid).toFixed(2)}{" "}
        </span>
      ),
    },
    {
      title: "Fees Balance (UGX)",
      dataIndex: ["pending_fees", "pendingFees"],
      key: "pending_fees",
      render: (text) => (
        <span style={{ color: text > 0 ? "red" : "inherit" }}>{text?.toLocaleString()}</span>
      ),
    },
  ];
  const thresholdColumns = [
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
    },
    {
      title: "Reg No.",
      dataIndex: "regNO",
      key: "regNO",
    },
    {
      title: "Stream",
      dataIndex: "stream",
      key: "stream",
    },
    {
      title: "Payment Percentage (%)",
      dataIndex: "percentage_paid",
      key: "percentage_paid",
      render: (percentagePaid) => (
        <span
          style={{
            color: percentagePaid < 100 && percentagePaid < 50 ? "red" : "blue",
          }}
        >
          {percentagePaid.toFixed(2)}%
        </span>
      ),
    },
    {
      title: "Fees Balance (UGX)",
      dataIndex: "pending_fees",
      key: "pending_fees",
      render: (text) => (
        <span style={{ color: text > 0 ? "red" : "inherit" }}>{text}</span>
      ),
    },
  ];

  /**
   *
   * @param {studentdata} dataObject
   * @param {pdf title} title
   * @description function to download odf for fee defaulters with percentages
   */
  const handleThresholdDataPdfDownload = (dataObject, title) => {
    setLoading(true);
    const doc = new JSPDF();
    const head = [
      ["No", "Student Name", "Reg No", "Stream"]
    ];
    if (withPayments === 1) {
      head[0].push("Payment Percentage (%)", "Fees Balance (UGX)");
    }

    doc.text(
      "Pdf Showing Fee Defaulters for given payment percentage",
      10,
      10
    );

    doc.text(`Date Printed : ${currentDate}`, 15, 20 ).setFontSize(10)
    doc.text(`Printed By : ${user?.data?.user?.name}`,15, 30 ).setFontSize(10)

    doc.autoTable({
      startY: 40,
      head: [["Class", "Term", "Year"]],
      body: [
        [selectedClass[0]?.name, selectedTerm[0]?.termName, selectedYear[0]?.yearName],
      ],
    });
    doc.autoTable({
      startY: 55,
      head:head,
      body: dataObject.map((student, index) => {
        const row = [
          index + 1,
          student?.student_name,
          student?.regNO,
          student.stream
        ];
  
        if (withPayments === 1) {
          row.push(
            parseFloat(student?.percentage_paid).toFixed(2),
           parseInt( student?.pending_fees).toLocaleString()
          );
        }
  
        return row;
      }),
    });

    doc.save(
      `${title}+${selectedClass[0]?.name}+${selectedTerm[0]?.termName}+${selectedYear[0]?.yearName}.pdf`
    );

    setLoading(false);
    setWithPayments(0);
  };

  /**
   *
   * @param {student data} dataObject
   * @description fucntion to download pdf for all class defaulters
   */
  const handleDefaultDataPDFDownload = (dataObject) => {
    setLoading(true);
    const doc = new JSPDF();
    const head = [
      ["No", "Student Name", "Reg No", "Stream"]
    ];
    if (withPayments === 1) {
      head[0].push("Payment Percentage (%)", "Fees Balance (UGX)");
    }

    doc.text(" PDF generated for all Fee Defaulters", 10, 10);
    doc.text(`Date Printed : ${currentDate}`, 15, 20 ).setFontSize(10)
    doc.text(`Printed By : ${user?.data?.user?.name}`,15, 30 ).setFontSize(10)
    doc.autoTable({
      startY: 40,
      head: [["Class", "Term", "Year"]],
      body: [
        [selectedClass[0]?.name, selectedTerm[0]?.termName, selectedYear[0]?.yearName],
      ],
    });
    doc.autoTable({
      startY: 55,
      head: head,
      body:  dataObject.map((student, index) => {
        const row = [
          index + 1,
          student?.student_name,
          student?.regNO,
          student.stream,
          
        ];
  
        if (withPayments === 1) {
          row.push(
            parseFloat(student?.pending_fees.percentagePaid).toFixed(2),
            parseInt(student?.pending_fees?.pendingFees).toLocaleString()
          );
        }
  
        return row;
      }),
      foot: withPayments === 1  && tableFooter(totalDefaultersAmount)
    });
    doc.save(
      `All_defaulters_${selectedClass[0]?.name}+Term+${selectedTerm[0]?.termName}+${selectedYear[0]?.yearName}.pdf`
    );

    setLoading(false);
  };

  useEffect(() => {
    getCurrentDate()
   
  }, []);

  return (
    <div>
      {defaultData && defaultData.length > 0 && (
        <>
          {" "}
          <h2 className="text-xl font-bold">All Defaulters</h2>
          <Table dataSource={defaultData} columns={columns} />{" "}
          {renderTotalAmount(totalDefaultersAmount)}
         <div > <input className="px-2 py-4"  type="checkbox" onChange={()=> {setWithPayments(1)}}  /> {" "} <label className="text-xl font-semibold">Download With Fees Balance</label> </div>
          <div>
            <button
              type="button"
              disabled={loading}
              className="px-6 m-2 py-3 bg-red-500 text-white rounded-md hover:bg-red-600"
              onClick={() => {
                handleDefaultDataPDFDownload(defaultData, defaultKey);
              }}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Download PDF"
              )}
            </button>
          </div>
        </>
      )}
      {belowThresholdData && belowThresholdData.length > 0 && threshold && (
        <>
          {" "}
          <h2 className="text-xl font-bold">Below {threshold}% Defaulters</h2>
          <Table
            dataSource={belowThresholdData}
            columns={thresholdColumns}
          />{" "}
          <input type="checkbox" onChange={()=> {setWithPayments(1)}}  /> {" "} <label className="text-xl font-semibold">Download With Fees Balance</label>
          <div>
            <button
              type="button"
              disabled={loading}
              className="px-6 m-2 py-3 bg-red-500 text-white rounded-md hover:bg-red-600"
              onClick={() => {
                handleThresholdDataPdfDownload(
                  belowThresholdData,
                  belowThresholdKey
                );
              }}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Download PDF"
              )}
            </button>
          </div>
        </>
      )}
      {aboveThresholdData && aboveThresholdData.length > 0 && threshold && (
        <>
          {" "}
          <h2 className="text-xl font-bold">Above {threshold}% Defaulters</h2>
          <Table
            dataSource={aboveThresholdData}
            columns={thresholdColumns}
          />{" "}

          <input  type="checkbox" onChange={()=> {setWithPayments(1)}}  /> {" "} <label className="text-xl font-semibold">Download With Fees Balance</label>
          <div>
            <button
              type="button"
              disabled={loading}
              className="px-6 m-2 py-3 bg-red-500 text-white rounded-md hover:bg-red-600"
              onClick={() => {
                handleThresholdDataPdfDownload(
                  aboveThresholdData,
                  aboveThresholdKey
                );
              }}
            >
              {loading ? (
                <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
              ) : (
                "Download PDF"
              )}
            </button>
          </div>
        </>
      )}
    </div>
  );
};

DefaultersTables.propTypes = {
  data: PropTypes.object,
  threshold: PropTypes.number,
  selectedClass: PropTypes.array,
  selectedTerm: PropTypes.array,
  selectedYear: PropTypes.array,
};

export default DefaultersTables;
