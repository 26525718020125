import React, {useState, useEffect} from "react";
import { Table } from "antd";
import { toast, ToastContainer } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";

function ViewSalaryStructure() {
  const [loading, setLoading] = useState(false);
  const [salarytypes, setSalaryTypes] = useState([]);
  const getSalaryType = ()=>{
    setLoading(true);
 

    const onSuccess = (response) => {
      setSalaryTypes(response.salary_types);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error("failed");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/salary-types`,
      "",
      "",
      onSuccess,
      onError
    );
  }

  const columns = [
    {title:"Name",
      dataIndex:"name",
      key:"name"
    },
    {
      title:"Amount",
      dataIndex:"amount",
      key:"name",
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
    }
  ]
  useEffect(() => {
    
    getSalaryType();
  }, []);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            Salary Structures
          </h1>
          <Table
            dataSource={loading ? Array(10).fill({}) : salarytypes}
            columns={columns}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 240,
            }}
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ViewSalaryStructure;
