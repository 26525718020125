import React, { useEffect, useState } from "react";
import Nav from "../../../nav";
import { toast, ToastContainer } from "react-toastify";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  DatePicker,
  Switch,
} from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import moment from "moment";

const { Option } = Select;

function ViewAsset() {
  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isViewModalVisible, setIsViewModalVisible] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [depreciationTimeline, setDepreciationTimeline] = useState([]);

  const getAssets = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setAssets(response);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (err) => {
      toast.error(
        err.response?.data?.message || "An error occurred while fetching assets"
      );
      setLoading(false);
    };
    await apiRequest(
      "get",
      `${API_BASE_URL}assets/viewAssets`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const updateAsset = async (values) => {
    const formattedValues = {
      ...values,
      purchase_date: values.purchase_date.format("YYYY-MM-DD"), // Format the date correctly
      id: selectedAsset.id,
    };

    const onSuccess = (response) => {
      toast.success(response.message);
      getAssets();
      setIsModalVisible(false);
    };

    const onError = (err) => {
      toast.error(
        err.response?.data?.message ||
          "An error occurred while updating the asset"
      );
    };

    await apiRequest(
      "post",
      `${API_BASE_URL}assets/updateAssets`,
      formattedValues,
      "",
      onSuccess,
      onError
    );
  };
  const getDepreciationTimeline = (id) => {
    const onSuccess = (response) => {
      toast.success(response.message);
      setDepreciationTimeline(response.timeline);
    };
    const onError = (error) => {
      toast.error("Error occured");
      setIsViewModalVisible(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}assets/${id}/depreciation-timeline`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleEditClick = (asset) => {
    setSelectedAsset(asset);
    setIsModalVisible(true);
  };
  console.log(depreciationTimeline);
  const handleViewClick = (asset) => {
    setSelectedAsset(asset);
    setIsViewModalVisible(true);
    getDepreciationTimeline(asset.id);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setIsViewModalVisible(false);
    setSelectedAsset(null);
  };

  const handleFormSubmit = (values) => {
    updateAsset(values);
  };

  const columns = [
    {
      title: "No",
      key: "no",
      render: (_, __, index) => index + 1,
      width: 55,
    },
    {
      title: "Asset ID",
      dataIndex: "asset_id",
      key: "asset_id",
      width: 120,
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      key: "name",
      width: 150,
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      key: "purchase_date",
      width: 100,
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Purchase Price (UGX)",
      dataIndex: "purchase_price",
      key: "purchase_price",

      width: 150,
      render: (text) =>
        text ? `${new Intl.NumberFormat().format(text)}` : "N/A",
    },
    {
      title: "Current Price (UGX)",
      dataIndex: "current_price",
      key: "current_price",
      width: 150,

      render: (text) =>
        text ? `${new Intl.NumberFormat().format(text)}` : "N/A",
    },
    {
      title: "Depreciation Percentage %",
      dataIndex: "depreciation_percentage",
      key: "depreciation_percentage",
      width: 180,
      render: (text) => (text !== undefined ? `${text}` : "N/A"),
    },
    {
      title: "Depreciation Frequency",
      dataIndex: "depreciation_frequency",
      key: "depreciation_frequency",
      width: 180,
      render: (text) =>
        text ? text.charAt(0).toUpperCase() + text.slice(1) : "N/A",
    },
    {
      title: "Availability",
      dataIndex: "availabity",
      key: "availabity",
      width: 110,
      render: (text) => (text === 1 ? "Available" : "Not Available"),
    },

    {
      title: "Action",
      key: "action",
      width: 170,
      fixed: "right",
      render: (_, record) => (
        <div className="flex justify-between mx-auto">
          <button
            className="border border-red-500 text-red-500 rounded-md bg-white px-4 py-1 hover:text-white hover:bg-red-500"
            onClick={() => handleEditClick(record)}
          >
            Edit
          </button>
          <button
            onClick={() => handleViewClick(record)}
            className="border border-green-500 text-green-500 rounded-md bg-white px-4 py-1 hover:text-white hover:bg-green-500"
          >
            View
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getAssets();
  }, []);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">View Assets</h1>
          <Table
            columns={columns}
            dataSource={loading ? Array(10).fill({}) : assets.assets}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 1700,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row className=" bg-gray-600 text-white font-bold">
                  <Table.Summary.Cell colSpan={4}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span>
                      {assets.totals &&
                      assets.totals.total_purchase_price !== undefined
                        ? parseInt(
                            assets.totals.total_purchase_price
                          ).toLocaleString()
                        : "N/A"}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={6}>
                    <span>
                      {assets.totals &&
                      assets.totals.total_current_price !== undefined
                        ? parseInt(
                            assets.totals.total_current_price
                          ).toLocaleString()
                        : "N/A"}
                    </span>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
            rowKey="asset_id"
          />
        </div>
      </div>
      <Modal
        title="Edit Asset"
        open={isModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        {selectedAsset && (
          <Form
            layout="vertical"
            onFinish={handleFormSubmit}
            initialValues={{
              name: selectedAsset.name,
              purchase_date: selectedAsset.purchase_date
                ? moment(selectedAsset.purchase_date)
                : null,
              purchase_price: selectedAsset.purchase_price,
              current_price: selectedAsset.current_price,
              depreciation_percentage: selectedAsset.depreciation_percentage,
              depreciation_frequency: selectedAsset.depreciation_frequency,
              availabity: selectedAsset.availabity === 1,
            }}
          >
            <Form.Item name="name" label="Asset Name">
              <Input />
            </Form.Item>
            <Form.Item name="purchase_date" label="Purchase Date">
              <DatePicker />
            </Form.Item>
            <Form.Item name="purchase_price" label="Purchase Price (UGX)">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
            <Form.Item name="current_price" label="Current Price (UGX)">
              <InputNumber
                style={{ width: "100%" }}
                disabled={selectedAsset.can_define_depreciation_percentage}
              />
            </Form.Item>
            <Form.Item
              name="depreciation_percentage"
              label="Depreciation Percentage"
            >
              <InputNumber
                disabled={!selectedAsset.can_define_depreciation_percentage}
                style={{ width: "100%" }}
              />
            </Form.Item>
            <Form.Item
              name="depreciation_frequency"
              label="Depreciation Frequency"
            >
              <Select
                disabled={!selectedAsset.can_define_depreciation_percentage}
              >
                <Option value="monthly">Monthly</Option>
                <Option value="yearly">Yearly</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="availabity"
              label="Availability"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Asset
              </Button>
            </Form.Item>
          </Form>
        )}
      </Modal>
      <Modal
        title="Asset Depreciate timeline"
        open={isViewModalVisible}
        onCancel={handleModalCancel}
        footer={null}
      >
        {selectedAsset && (
          <>
            {/* <div className="container mx-auto p-4">
              <h1 className="text-2xl font-bold mb-4">Depreciation Timeline</h1>
              <div className="relative border-l border-gray-200 dark:border-gray-700">
                {depreciationTimeline.map((item, index) => (
                  <div className="mb-10 ml-4" key={index}>
                    <div className="absolute w-3 h-3 bg-blue-500 rounded-full -left-1.5 border border-white dark:border-gray-900"></div>
                    <time className="mb-1 text-sm font-normal leading-none text-gray-400 dark:text-gray-500">
                      {item.date}
                    </time>
                    <p className="mb-2 text-lg font-semibold text-gray-900 dark:text-white">
                      {item.price.toLocaleString()}
                    </p>
                  </div>
                ))}
              </div>
            </div> */}
            <div className="container mx-auto p-4">
              <h1 className="text-2xl font-bold mb-4">Depreciation Timeline</h1>
              <table className="min-w-full bg-white">
                <thead>
                  <tr>
                    <th className="py-2 px-4 bg-gray-200">Date</th>
                    <th className="py-2 px-4 bg-gray-200">Price(UGX)</th>
                  </tr>
                </thead>
                <tbody>
                  {depreciationTimeline.map((item, index) => (
                    <tr key={index} className="border-t">
                      <td className="py-2 px-4">{item.date}</td>
                      <td className="py-2 px-4">
                        {parseInt(item.price).toLocaleString()}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </>
        )}
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ViewAsset;
