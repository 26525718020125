import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Table, Button, Modal, Form, Input, Select } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";

function ViewExpenseCategory() {
  const [loading, setLoading] = useState(false);
  const [expenseCategories, setExpenseCategories] = useState([]);
   const [isCategoryModalVisible, setCategoryModalVisible] = useState(false);
   const [isSubCategoryModalVisible, setSubCategoryModalVisible] =
     useState(false);
   const [currentCategory, setCurrentCategory] = useState(null);
   const [currentSubCategory, setCurrentSubCategory] = useState(null);

  const getExpenses = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setExpenseCategories(response.expense_categories);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/expense-categories`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const handleCategoryEdit = (record) => {
    setCurrentCategory(record);
    setCategoryModalVisible(true);
  };

  const handleSubCategoryEdit = (record) => {
    setCurrentSubCategory(record);
    setSubCategoryModalVisible(true);
  };

  const handleCategoryUpdate = async (values) => {
    console.log(values)
    try {
      await apiRequest(
        "post",
        `${API_BASE_URL}payroll/expense-categories/update`,
        JSON.stringify(values),
        "",
        (response) => {
          toast.success(response.message);
          setCategoryModalVisible(false);
          getExpenses(); // Refresh the list after update
        },
        (error) => {
          toast.error(error?.response?.data?.message);
          console.log(error);
        }
      );
    } catch (error) {
      toast.error("An unexpected error occurred.");
    }
  };

  const handleSubCategoryUpdate = async (values) => {
    try {
      await apiRequest(
        "post",
        `${API_BASE_URL}payroll/expense-subcategories/update`,
        JSON.stringify(values),
        "",
        (response) => {
          toast.success(response.message);
          setSubCategoryModalVisible(false);
          getExpenses(); // Refresh the list after update
        },
        (error) => {
          toast.error(error?.response?.data?.message);
          console.log(error);
        }
      );
    } catch (error) {
      toast.error("An unexpected error occurred.");
    }
  };

  const mainColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button className="py-1 px-4 rounded-md border text-red-500 border-red-500 bg-white hover:bg-red-500 hover:text-white" onClick={() => handleCategoryEdit(record)} >
          Edit
        </button>
      ),
    },
  ];

  const subColumns = [
    {
      title: "Sub-Category Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <button
          className="py-1 px-4 rounded-md border text-red-500 border-red-500 bg-white hover:bg-red-500 hover:text-white"
          onClick={() => handleSubCategoryEdit(record)}
        >
          Edit
        </button>
      ),
    },
  ];

  const expandedRowRender = (record) => {
    const subCategories = record.sub_cat || [];
    return (
      <Table
        columns={subColumns}
        dataSource={subCategories}
        pagination={false}
        rowKey="id"
        size="small"
      />
    );
  };

  useEffect(() => {
    getExpenses();
  }, []);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            View Expense Categories{" "}
          </h1>
          <Table
            dataSource={loading ? Array(10).fill({}) : expenseCategories}
            columns={mainColumns}
            expandedRowRender={expandedRowRender}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 1700,
            }}
            rowKey="id"
          />
        </div>
      </div>
      {/* Category Edit Modal */}
      <Modal
        title="Edit Expense Category"
        open={isCategoryModalVisible}
        onCancel={() => setCategoryModalVisible(false)}
        footer={null}
      >
        <Form initialValues={currentCategory} onFinish={handleCategoryUpdate}>
          <Form.Item name="id" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              { required: true, message: "Please input the category name!" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      {/* Sub-Category Edit Modal */}
      <Modal
        title="Edit Expense Sub-Category"
        open={isSubCategoryModalVisible}
        onCancel={() => setSubCategoryModalVisible(false)}
        footer={null}
      >
        <Form
          initialValues={currentSubCategory}
          onFinish={handleSubCategoryUpdate}
        >
          <Form.Item name="id" hidden>
            <Input type="hidden" />
          </Form.Item>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Please input the sub-category name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description">
            <Input.TextArea />
          </Form.Item>
          <Form.Item
            name="parent_cat"
            label="Parent Category"
            rules={[
              { required: true, message: "Please select the parent category!" },
            ]}
          >
            <Select
              options={expenseCategories.map((category) => ({
                value: category.id,
                label: category.name,
              }))}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ViewExpenseCategory;
