import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";

function AddIncomes() {
  const { settings } = useSelector((state) => state.settings.settings);

  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState({});
  const [months, setMonths] = useState([]);
  const [formData, setFormData] = useState({
    source: "",
    amount: "",
    description: "",
    month_id: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessage({ ...errorMessage, [name]: null });
  };
  const handleMonthChange = (value) => {
    setFormData({
      ...formData,
      month_id: value,
    });
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.amount) {
      newErrors.amount = "Amount paid is required";
    }

    if (!formData.source) {
      newErrors.source = "Income source is Required";
    }
    if (!formData.month_id) {
      newErrors.month_id = "Month is required";
    }
    setErrorMessage(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (!validateForm) {
      return;
    }
    setLoading(true);
    const onSuccess = (response) => {
      toast.success(response.message);
      setFormData({
        amount: "",
        source: "",
        description: "",
      });
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Error Occurred ... try again");
      setLoading(false);
    };
    let data = {
      amount: formData.amount,
      description: formData.description,
      source: formData.source,
      year_id: settings[0]?.currentYear,
      term_id: settings[0]?.currentTerm,
      month_id: formData.month_id,
    };

    apiRequest(
      "post",
      `${API_BASE_URL}payroll/incomes`,
      data,
      "",
      onSuccess,
      onError
    );
  };

  const getMonths = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setMonths(response.months);
      setLoading(false);
    };
    const onError = () => {
      toast.error("failed to retrieve months");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/months`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const filterMonthOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const monthOptions = months?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));
  useEffect(() => {
    getMonths();
  }, []);
  return (
    <div>
      <Nav />

      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1>Add Incomes </h1>
          <div className="max-w-2xl mx-auto">
            <form className="bg-white shadow-sm rounded px-8 pt-6 pb-8 mb-4">
              <h1 className=" text-lg justify-center items-center flex font-semibold mb-3">
                Enter Income details
              </h1>

              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.source && "border-red-500"
                  }`}
                  id="source"
                  name="source"
                  type="text"
                  placeholder=" "
                  value={formData.source}
                  onChange={handleChange}
                />
                {errorMessage.source && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.source}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Income Source *
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.description && "border-red-500"
                  }`}
                  id="description"
                  name="description"
                  type="text"
                  placeholder=" "
                  value={formData.description}
                  onChange={handleChange}
                />
                {errorMessage.description && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.description}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Description *
                </label>
              </div>
              <div className="mb-4 relative h-11 w-full min-w-[200px] mt-3">
                <input
                  className={`peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full ${
                    errorMessage.amount && "border-red-500"
                  }`}
                  id="amount"
                  name="amount"
                  type="number"
                  min="500"
                  step="500"
                  placeholder=" "
                  value={formData.amount}
                  onChange={handleChange}
                />
                {errorMessage.amount && (
                  <span className="text-xs text-red-500  flex justify-center ">
                    {errorMessage.amount}
                  </span>
                )}
                <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Amount *
                </label>
              </div>
              <div className=" mb-9 relative h-11 w-full min-w-[200px] ">
                <label className="">Select Month</label>
                <div className=" relative h-11 w-full min-w-[200px]">
                  <Select
                    showSearch
                    placeholder="Select Month"
                    optionFilterProp="children"
                    className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
                    onChange={handleMonthChange}
                    filterOption={filterMonthOption}
                    size="large"
                    options={monthOptions}
                  />
                </div>
              </div>

              <div className="flex justify-center">
                <button
                  type="button"
                  disabled={loading}
                  className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600"
                  onClick={handleSubmit}
                >
                  {loading ? (
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
                  ) : (
                    "Add Income"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default AddIncomes;
