import React, { useState } from "react";
import { FaUserCircle, FaSignOutAlt } from "react-icons/fa";
import { logoutUser } from "../redux/slice/authSlice";
import { FaHandHoldingDollar } from "react-icons/fa6";
import { TbCreditCardPay, TbSettingsFilled } from "react-icons/tb";
import { GiTakeMyMoney, GiPayMoney, GiBassetHoundHead } from "react-icons/gi";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate, Link } from "react-router-dom";
import SettingsModal from "../StudentAccouting/js/SettingsModal";

function Nav() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.auth);

  const [dropdownOpen, setDropDownOpen] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [openFeesDropDown, setOpenFeesDropDown] = useState(false);
  const [openPaymentsDropDown, setOpenPaymentsDropDown] = useState(false);
  const [openPayrollDropDown, setOpenPayrollDropDown] = useState(false);
  const [openPayrollPaymentsDropDown, setOpenPayrollPaymentsDropDown] =
    useState(false);
    const [openIncomesDropDown, setOpenPIncomessDropDown] =
      useState(false);
  const [mobileDropDown, setMobileDropDown] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [assetDropdown, setAssetDropdown] = useState(false);
  const handleDropDown = () => {
    setDropDownOpen(!dropdownOpen);
  };
  const handleSidebarToggle = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const handleSettingsModal = () => {
    setSettingsModal(true);
  };

  const handleFeesDropDown = () => {
    setOpenFeesDropDown(!openFeesDropDown);
  };
  const handlePaymentDropDown = () => {
    setOpenPaymentsDropDown(!openPaymentsDropDown);
  };
  const handlePayrollDropDown = () => {
    setOpenPayrollDropDown(!openPayrollDropDown);
  };
  const handlePayrollPaymentDropDown = () => {
    setOpenPayrollPaymentsDropDown(!openPayrollPaymentsDropDown);
  };
  const handleIncomesDropdown = () =>{
    setOpenPIncomessDropDown(!openIncomesDropDown);
  }
   const handleAssetsDropdown = () => {
     setAssetDropdown(!assetDropdown);
   };

  const handleMobileDropDwon = () => {
    setMobileDropDown(!mobileDropDown);
  };

  const handleLogout = async () => {
    try {
      await dispatch(logoutUser()); // Dispatch loginUser action with form data
      localStorage.removeItem("user");
      localStorage.removeItem("encryptedData");
      localStorage.removeItem("persist:root");
      toast.success("Logout Successful");
      navigate("/");
    } catch (error) {
      toast.error("error occured");
    }
  };

  return (
    <>
      <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 ">
        <div className="px-3 py-3 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start rtl:justify-end">
              <button
                data-drawer-target="logo-sidebar"
                data-drawer-toggle="logo-sidebar"
                aria-controls="logo-sidebar"
                type="button"
                onClick={handleSidebarToggle}
                className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200  "
              >
                <span className="sr-only">Open sidebar</span>
                <svg
                  className="w-6 h-6"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                  ></path>
                </svg>
              </button>
              <Link to="/feesummary" className="flex ms-2 md:me-24">
                <img
                  src="https://flowbite.com/docs/images/logo.svg"
                  className="h-8 me-3"
                  alt="FlowBite Logo"
                />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text  bg-gray-100 rounded-full ">
                  Accounting
                </span>
              </Link>
            </div>
            <div className="lg:flex items-center float-right hidden">
              <div className="flex items-center ms-3">
                <span className="m-2 bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text font-bold text-xl bg-gray-100 rounded-full">
                  {user?.data?.user?.name}
                </span>
                <button
                  type="button"
                  className="flex text-sm bg-gray-100 rounded-full focus:ring-4 focus:ring-gray-300 "
                  aria-expanded="false"
                  data-dropdown-toggle="dropdown-user"
                  onClick={handleDropDown}
                >
                  <span className="sr-only">Open user menu</span>
                  <FaUserCircle className="w-8 h-8 rounded-full" />
                </button>

                {dropdownOpen && (
                  <div
                    className="absolute right-0 top-full z-50 mt-2 text-base bg-white divide-y divide-gray-100 rounded shadow"
                    id="dropdown-user"
                  >
                    <ul>
                      <span onClick={handleSettingsModal}>
                        <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-red-900">
                          Settings
                        </span>
                      </span>
                      <li>
                        <span
                          onClick={handleLogout}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        >
                          Sign out
                        </span>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            </div>

            <div className="lg:hidden">
              <button
                type="button"
                id="multiLevelDropdownButton"
                data-dropdown-toggle="multi-dropdown"
                className=" bg-gray-100 rounded-full focus:ring-4 focus:ring-gray-300 text-sm text-center inline-flex items-center "
                aria-expanded="false"
                onClick={handleMobileDropDwon}
              >
                <span className="sr-only">Open user menu</span>
                <FaUserCircle className="w-8 h-8 rounded-full" />
              </button>
              {mobileDropDown && (
                <div
                  id="multi-dropdown"
                  className="z-10 absolute  right-3  bg-white divide-y divide-gray-100 rounded-lg shadow w-44 "
                >
                  <ul
                    className="py-2 text-sm text-gray-700 "
                    aria-labelledby="multiLevelDropdownButton"
                  >
                    <li>
                      <span className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-100 bg-gradient-to-r from-blue-500 to-green-500 text-transparent bg-clip-text font-bold text-sm ">
                        {user?.data?.user?.name}
                      </span>
                    </li>

                    <li>
                      <span
                        className="block px-4 py-2 text-red-500 hover:bg-gray-100 dark:hover:bg-gray-600"
                        // onClick={handleLogout}
                      >
                        Sign out
                      </span>
                    </li>
                    <span onClick={handleSettingsModal}>
                      <TbSettingsFilled
                        size={35}
                        className="flex-shrink-0 text-gray-500 transition duration-75  group-hover:text-red-900 "
                      />
                      <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-red-900">
                        Settings
                      </span>
                    </span>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-5 lg:top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform bg-white border-r border-gray-200 sm:translate-x-0 md:translate-x-0 ${
          sidebarOpen ? "" : "-translate-x-full" // Conditional className to control visibility
        }`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto no-scrollbar bg-white">
          <ul className="space-y-2 font-medium">
            <li>
              <span
                onClick={handleLogout}
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100"
              >
                <FaSignOutAlt
                  size={35}
                  className="flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-red-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-red-900">
                  Sign Out
                </span>
              </span>
            </li>
            <div class="flex items-center">
              <div class="flex-1 h-0.5 bg-gray-200"></div>
              <div class="px-4 text-gray-600">Students</div>
              <div class="flex-1 h-0.5 bg-gray-200"></div>
            </div>

            <li>
              <button
                type="button"
                onClick={handleFeesDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <TbCreditCardPay
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-blue-900">
                  Fees
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${openFeesDropDown ? "" : "hidden"}`}
              >
                <li>
                  <Link
                    to="/create/feeType"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Fee Type
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/feeTypes"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Fee Types
                  </Link>
                </li>
                <li>
                  <Link
                    to="/create/feeStructure"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Fee Structure
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/feeStructure"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Fee Structures
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button
                type="button"
                onClick={handlePaymentDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <GiPayMoney
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-blue-900">
                  Payments
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openPaymentsDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  <Link
                    to="/add/payment"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Register Payment
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/payments"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Payments
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/editedpayments"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Edited Payments
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/financial-statements"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Student's Financials
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/feesdefaulters"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Fees Defaulters
                  </Link>
                </li>
              </ul>
            </li>
            <div class="flex items-center">
              <div class="flex-1 h-0.5 bg-gray-200"></div>
              <div class="px-4 text-gray-600">Staff</div>
              <div class="flex-1 h-0.5 bg-gray-200"></div>
            </div>
            <li>
              <button
                type="button"
                onClick={handlePayrollDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <TbCreditCardPay
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-blue-900">
                  Payroll
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openPayrollDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  <Link
                    to="/add-salary"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Salary Type
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/salary-structures"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Salary Types
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-allowances"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Allowances
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view/allowances"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Allowances
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view-staff"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Staff
                  </Link>
                </li>
                <li>
                  <Link
                    to="/assign-salary"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Assign Staff Salaries
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-payment"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Salary Payment
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <button
                type="button"
                onClick={handlePayrollPaymentDropDown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <GiTakeMyMoney
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-blue-900">
                  Expenses
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openPayrollPaymentsDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  <Link
                    to="/view-payments"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Payments
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-expense-categories"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Expense Categories
                  </Link>
                </li>
                <li>
                  <Link
                    to="/add-expense-subcat"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Expense SubCategories
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view-expense-categories"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Expense Categories
                  </Link>
                </li>

                <li>
                  <Link
                    to="/add-expense"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Expenses
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view-expenses"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Expenses
                  </Link>
                </li>
              </ul>
            </li>

            <div class="flex items-center">
              <div class="flex-1 h-0.5 bg-gray-200"></div>
              <div class="px-4 text-gray-600">Revenue</div>
              <div class="flex-1 h-0.5 bg-gray-200"></div>
            </div>
            <li>
              <button
                type="button"
                onClick={handleIncomesDropdown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <FaHandHoldingDollar
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-blue-900">
                  Incomes
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${
                  openIncomesDropDown ? "" : "hidden"
                }`}
              >
                <li>
                  <Link
                    to="/addIncome"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Incomes
                  </Link>
                </li>
                <li>
                  <Link
                    to="/viewIncome"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Incomes
                  </Link>
                </li>
              </ul>
            </li>
            <div class="flex items-center">
              <div class="flex-1 h-0.5 bg-gray-200"></div>
              <div class="px-4 text-gray-600">Assets</div>
              <div class="flex-1 h-0.5 bg-gray-200"></div>
            </div>
            <li>
              <button
                type="button"
                onClick={handleAssetsDropdown}
                className="flex items-center w-full p-2 text-base text-gray-900 transition duration-75 rounded-lg group hover:bg-gray-100"
                aria-controls="dropdown-example"
                data-collapse-toggle="dropdown-example"
              >
                <GiBassetHoundHead
                  size={35}
                  className="text-gray-500 transition duration-75 group-hover:text-blue-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-blue-900">
                  Assets
                </span>
                <svg
                  className="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 10 6"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m1 1 4 4 4-4"
                  />
                </svg>
              </button>
              <ul
                id="dropdown-example"
                className={`py-2 space-y-2 ${assetDropdown ? "" : "hidden"}`}
              >
                <li>
                  <Link
                    to="/add-asset"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    Add Assets
                  </Link>
                </li>
                <li>
                  <Link
                    to="/view-asset"
                    className="flex items-center w-full p-2 text-gray-900 transition duration-75 rounded-lg pl-5 group hover:bg-gray-100 hover:text-red-400"
                  >
                    View Assets
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <span
                onClick={handleSettingsModal}
                className="flex items-center p-2 text-gray-900 rounded-lg hover:bg-gray-100"
              >
                <TbSettingsFilled
                  size={35}
                  className="flex-shrink-0 text-gray-500 transition duration-75 group-hover:text-red-900"
                />
                <span className="flex-1 ms-3 whitespace-nowrap text-gray-500 group-hover:text-red-900">
                  Settings
                </span>
              </span>
            </li>
          </ul>
        </div>
      </aside>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <SettingsModal open={settingsModal} setOpen={setSettingsModal} />
    </>
  );
}

Nav.propTypes = {
  branchSwitch: PropTypes.bool,
  setBranchSwitch: PropTypes.func,
};
export default Nav;
