import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Table } from "antd";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";
import StaffModal from "./StaffModal";

function ViewStaff() {
  const [loading, setLoading] = useState(false);
  const [staff, setStaff] = useState([]);
  const [staffId, setStaffId] = useState(null);
  const [openStaffModal, setOpenStaffModal] = useState(false);
  const openModal = (record) => {
    setOpenStaffModal(true);
    setStaffId(record);
  };
  const getStaff = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setStaff(response.staff);
      // toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/staff`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name" },
    {
      title: "Action",
      dataIndex: "",
      key: "x",
      width: 100,
      fixed: "right",
      render: (record) => (
        <button
          onClick={() => openModal(record)}
          className="border rounded-lg border-red-500 px-4 py-2 text-red-400 hover:bg-red-400 hover:text-white"
        >
          View
        </button>
      ),
    },
  ];

  useEffect(() => {
    getStaff();
  }, []);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">Staff</h1>
          <Table
            dataSource={loading ? Array(10).fill({}) : staff}
            columns={columns}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 240,
            }}
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <StaffModal
        open={openStaffModal}
        setOpen={setOpenStaffModal}
        staff={staffId}
      />
    </div>
  );
}

export default ViewStaff;
