import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_BASE_URL } from "../../common/constants";
export const fetchClasses = createAsyncThunk(
    "classes",
    async (_, { getState }) => {
      try {
        const { user } = getState().auth;
        const accessToken = user?.data?.access_token;
        if (!accessToken) {
          throw new Error("Access token not found");
        }
  
        const response = await axios.get(
          `${API_BASE_URL}get/classes`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  );

  const classSlice = createSlice({
    name: "classes",
    initialState: {
      classes: [],
      status: "idle",
      error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
      builder
        .addCase(fetchClasses.pending, (state) => {
          state.status = "loading";
        })
        .addCase(fetchClasses.fulfilled, (state, action) => {
          state.status = "succeeded";
          state.classes = action.payload.classes;
        })
        .addCase(fetchClasses.rejected, (state, action) => {
          state.status = "failed";
          state.error = action.error.message;
        })
    },
  });
  
  export default classSlice.reducer;