import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiRequest from "../../common/apiRequest";
import { API_BASE_URL } from "../../common/constants";
import { Divider, Modal } from "antd";

function SettingsModal({ open, setOpen }) {
  const [payrollSettings, setPayrollSettings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [checkboxes, setCheckboxes] = useState({});

  const getPayrollSettings = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setPayrollSettings(response.payroll_settings);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/payrollsettings`,
      "",
      "",
      onSuccess,
      onError
    );
  };
  const handleCancel = () => {
    setOpen(!open);
  };

  const updatePayrollSettings = () => {
    const onSuccess = (response) => {
      toast.success(response.message);
    };
    const onError = (error) => {
      toast.error("Settings Update Failed");
    };
    apiRequest(
      "post",
      `${API_BASE_URL}payroll/payroll-settings`,
      checkboxes,
      "",
      onSuccess,
      onError
    );
  };
  useEffect(() => {
    getPayrollSettings();
  }, [open]);

  useEffect(() => {
    // Set checkboxes based on payrollSettings after it's populated
    if (payrollSettings) {
      setCheckboxes({
        apply_nssf: payrollSettings?.apply_nssf,
        apply_paye: payrollSettings?.apply_paye,
      });
    }
  }, [payrollSettings]);
  return (
    <div>
      <Modal
        title="Settings"
        footer={[
          <button
            className="mt-6  mr-6   select-none rounded-lg bg-red-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-red-500/20 transition-all hover:shadow-lg hover:shadow-red-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            key="back"
            onClick={handleCancel}
          >
            Cancel
          </button>,
          <button
            type="button"
            disabled={loading}
            className="mt-6  mr-6   select-none rounded-lg bg-green-600 py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase text-white shadow-md shadow-green-500/20 transition-all hover:shadow-lg hover:shadow-green-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none"
            onClick={updatePayrollSettings}
          >
            {loading ? (
              <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-6 w-6"></div> // CSS loader
            ) : (
              "Update Settings"
            )}
          </button>,
        ]}
        open={open}
        onCancel={handleCancel}
      >
        <h1 className="text-2xl mb-2"> Payroll Settings</h1>
        <Divider />
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            name="apply_nssf"
            className="form-checkbox h-5 w-5 text-indigo-600"
            checked={checkboxes.apply_nssf === 1}
            onChange={(e) =>
              setCheckboxes({
                ...checkboxes,
                apply_nssf: e.target.checked ? 1 : 0,
              })
            }
          />
          <span className="ml-2 text-gray-700">Apply NSSF</span>
        </label>
        <br />
        <label className="inline-flex items-center mt-2">
          <input
            type="checkbox"
            name="apply_paye"
            className="form-checkbox h-5 w-5 text-indigo-600"
            checked={checkboxes.apply_paye === 1}
            onChange={(e) =>
              setCheckboxes({
                ...checkboxes,
                apply_paye: e.target.checked ? 1 : 0,
              })
            }
          />
          <span className="ml-2 text-gray-700">Apply PAYE</span>
        </label>
        <Divider />
      </Modal>
    </div>
  );
}

export default SettingsModal;
