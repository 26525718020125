import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { ToastContainer, toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav"


function ViewAllowances() {
  const [loading, setLoading] = useState(false);
  const [allowances, setAllowances] = useState([]);

  const getAllowances = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setAllowances(response.allowances);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      console.log(error)
      toast.error("failed");
      setLoading(false);
    };

    apiRequest("get", `${API_BASE_URL}payroll/allowances`, "", "", onSuccess, onError);
  };

  const columns = [
    {title:"Name",
      dataIndex:"name",
      key:"name"
    },
    {
      title:"Amount",
      dataIndex:"amount",
      key:"amount",
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
    }
  ]

  useEffect(() => {
    getAllowances();
  }, []);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">Allowances</h1>
          <Table
            dataSource={loading ? Array(10).fill({}) : allowances}
            columns={columns}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 1700,
            }}
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ViewAllowances;
