import React, { useState, useEffect } from "react";
import { Table, Drawer, Divider, Select } from "antd";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import { toast } from "react-toastify";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import Nav from "../../../nav";

function ViewSalaryPayments() {
  const { terms } = useSelector((state) => state.terms);
  const { years } = useSelector((state) => state.years);
  const { settings } = useSelector((state) => state.settings.settings);
  const [loading, setLoading] = useState(false);
  const [staffPayments, setStaffPayments] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [months, setMonths] = useState([]);

  const [formData, setFormData] = useState({
    startDate: "",
    endDate: "",
    term_id: "",
    payment_type: "",
    year: "",
    month: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handlePaymentTypeChange = (e) => {
    const selectedMethodId = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      payment_type: selectedMethodId,
    }));
  };
  const handleMonthChange = (value) => {
    setFormData({
      ...formData,
      month: value,
    });
  };
  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };
  const onChangeTerm = (value) => {
    setFormData({
      ...formData,
      term_id: value,
    });
  };
  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
    setFormData({
      startDate: "",
      endDate: "",
      term_id: "",
      payment_type: "",
    });
  };

  const termOptions = terms?.map((method) => ({
    value: method.id.toString(),
    label: method.termName,
  }));
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));
  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const filterMonthOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const monthOptions = months?.map((x) => ({
    value: x.id.toString(),
    label: x.name,
  }));

  const getMonths = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setMonths(response.months);
    };
    const onError = () => {
      toast.error("failed to retrieve months");
    };
    apiRequest(
      "get",
      `${API_BASE_URL}payroll/months`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const getStaffPayments = () => {
    setLoading(true);

    const onSuccess = (response) => {
      setStaffPayments(response);
      toast.success(response.message);
      setLoading(false);
    };

    const onError = (error) => {
      toast.error(error?.response?.data?.message);
      setLoading(false);
    };
    let params = {
      start_date: formData.startDate,
      end_date: formData.endDate,
      term_id: formData.term_id || settings[0]?.currentTerm,
      year: formData.year || settings[0]?.currentYear,
      payment_type: formData.payment_type,
      month_id: formData.month,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}payroll/staff-payments`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: ["staff", "name"],
      key: "name",
      width: 150,
      fixed: "left",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      key: "payment_type",

      width: 90,
    },
    {
      title: "Payment Method",
      dataIndex: ["payment_method", "name"],
      key: "payment_method",
      width: 100,
    },
    {
      title: "Term",
      dataIndex: ["term", "termName"],
      key: "term",
      width: 100,
    },
    {
      title: "Month Paid For",
      dataIndex: ["month", "name"],
      key: "month",
      width: 100,
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      key: "payment_date",
      width: 100,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        const date = new Date(text);
        return !isNaN(date.getTime()) ? format(date, "PPP p") : "Invalid Date";
      },
      width: 120,
    },
    {
      title: "Amount UGX",
      dataIndex: "amount",
      key: "amount",
      fixed: "right",
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
      width: 100,
    },
  ];
  useEffect(() => {
    getStaffPayments();
    getMonths();
  }, []);

  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            View Payments{" "}
          </h1>
          <div className="hidden lg:flex float-end">
            <button
              onClick={toggleFilters}
              className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Filters
            </button>
          </div>
          <Table
            dataSource={
              loading ? Array(10).fill({}) : staffPayments.staff_payments
            }
            columns={columns}
            pagination={{
              pageSize: 50,
            }}
            scroll={{
              y: 1700,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row className=" bg-gray-600 text-white font-bold">
                  <Table.Summary.Cell colSpan={7}>Total</Table.Summary.Cell>
                  <Table.Summary.Cell>
                    <span>
                      {parseInt(staffPayments.total_amount).toLocaleString()}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell colSpan={1}></Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>
      <Drawer
        title="Filters"
        placement="right"
        closable={true}
        onClose={onClose}
        open={showFilters}
        getContainer={false}
      >
        <div className=" mb-5 relative h-11 w-full min-w-[200px] mt-3">
          <label className="">Select Start Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-8 relative h-11 w-full min-w-[200px] mt-6">
          <label className="">Select End Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mb-4 relative h-11 w-full min-w-[200px]">
          <select
            className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
            id="payment_type"
            name="payment_type"
            value={formData.payment_type}
            onChange={handlePaymentTypeChange}
          >
            <option value="">Select Payment Type</option>
            <option key="salary" value="salary">
              Salary Payment
            </option>
            <option key="allowance" value="allowance">
              Allowance Payment
            </option>
          </select>
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Payment Type *
          </label>
        </div>
        <div className="relative h-11 w-full min-w-[200px]">
          <label className="">Filter By Term</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Term"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
              onChange={onChangeTerm}
              filterOption={filterTermOption}
              size="large"
              options={termOptions}
            />
          </div>
        </div>
        <div className="relative  h-11 w-full min-w-[200px] mt-9">
          <label className="">Filter By Year</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Year"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
              onChange={onChangeYear}
              filterOption={filterYearOption}
              size="large"
              options={yearOptions}
            />
          </div>
        </div>
        <div className=" mb-9 mt-5 relative h-11 w-full min-w-[200px] ">
          <label className="">Select Month</label>
          <div className="  relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Month"
              optionFilterProp="children"
              className="h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              onChange={handleMonthChange}
              filterOption={filterMonthOption}
              size="large"
              options={monthOptions}
            />
          </div>
        </div>
        <Divider />
        <div className="flex justify-between mt-4">
          <button
            type="button"
            className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
            onClick={() => {
              setShowFilters(false);
              getStaffPayments();
            }}
          >
            Apply Filters
          </button>
          <button
            onClick={() => {
              resetFilters();
              getStaffPayments();
            }}
            className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
          >
            Reset Filters
          </button>
        </div>
      </Drawer>
    </div>
  );
}

export default ViewSalaryPayments;
