import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";

const ClassPieChart = ({ data }) => {
  // Calculate total amount to pay and total fees collected
  const totalToPay = data && data.reduce((acc, curr) => acc + curr.totalFeesToPay, 0);
  const totalCollected = data && data.reduce(
    (acc, curr) => acc + parseFloat(curr.totalFeesPaid),
    0
  );

  // Calculate remaining balance
  const remainingBalance = totalCollected - totalToPay  ;

  ChartJS.register(ArcElement, Tooltip, Legend);

  // Calculate percentage values
  const totalToPayPercentage = (totalCollected / totalToPay) * 100;
  const remainingBalancePercentage = (remainingBalance / totalToPay) * 100;

  // Chart data
  const chartData = {
    labels: ["Total Fees Collected", "Remaining Fees Balance"],
    datasets: [
      {
        data: [totalCollected, remainingBalance],
        backgroundColor: ["#FF6384", "#36A2EB"],
        hoverBackgroundColor: ["#FF6384", "#36A2EB"],
      },
    ],
  };
  

  return (
    <>
       <span className="text-center font-bold text-green-500 text-sm ">Total Fees Expected: UGX {parseInt(totalToPay ? totalToPay : 0).toLocaleString()}</span>
       <span className="text-center font-bold text-red-500 text-sm">Total Fees Collected : UGX {parseInt(totalCollected ? totalCollected : 0).toLocaleString()} ({totalToPayPercentage.toFixed(1)}%)</span>
       <span className="text-center font-bold text-blue-500 text-sm">Total Fees Remaining: UGX {parseInt(remainingBalance ? remainingBalance : 0).toLocaleString()}{remainingBalance > 0 && " excess"}</span>
      <Pie data={chartData} />
    </>
  );
};

export default ClassPieChart;
