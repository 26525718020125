import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { Table, Skeleton, Empty, Drawer, Select, Divider } from "antd";
import Nav from "../../../nav";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import PaymentAmountUpdateModal from "./PaymentAmountUpdateModal";
import { useSelector } from "react-redux";

function ViewPayments() {
  const {settings} = useSelector((state) => state.settings.settings);
  const {years} = useSelector((state) => state.years);
  const {terms} = useSelector((state) => state.terms );
  const [payments, setPayments] = useState([]);
  const [payment, setPayment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);
  const [formData, setFormData] = useState({
    year: "",
    term: "",
    regNo: "",
    paymentMethod: "",
    startDate:"",
    endDate:""
  });

  const resetFilters = () => {
    setFormData({
      year: "",
      term: "",
      regNo: "",
      paymentMethod: "",
    });
  };
  const yearOptions = years?.map((year) => ({
    value: year.id.toString(),
    label: year.yearName,
  }));
  const filterYearOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const paymentMethodOptions = paymentMethods?.map((method) => ({
    value: method.id.toString(),
    label: method.name,
  }));
  const filterPaymentMethodOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());
  const termOptions = terms?.map((method) => ({
    value: method.id.toString(),
    label: method.termName,
  }));
  const filterTermOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  const onChangeYear = (value) => {
    setFormData({
      ...formData,
      year: value,
    });
  };
  const onChangeTerm = (value) => {
    setFormData({
      ...formData,
      term: value,
    });
  };
  const onChangePaymentMethod = (value) => {
    setFormData({
      ...formData,
      paymentMethod: value,
    });
  };


  const fetchPaymentMethods = async () => {
    setLoading(true);
    const onSuccess = (response) => {
      setPaymentMethods(response.feesPaymentMethods);
      setLoading(false);
    };
    
    const onError = (error) => {
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    };

    apiRequest(
      "get",
      `${API_BASE_URL}get/feePaymentMethods`,
      "",
      "",
      onSuccess,
      onError
    );
  };

  const fetchPayments = () => {
    setLoading(false);
    let params = {
      term:formData.term || settings[0]?.currentTerm, 
      paymentMethod:formData.paymentMethod,
      year: formData.year || settings[0]?.currentYear,
      regNo:formData.regNo,
      start_date: formData.startDate,
      end_date: formData.endDate,
    }
    const onSuccess = (response) => {
      setPayments(response.payments);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error("Failed to retreive Payments");
      setLoading(false);
    };
    apiRequest(
      "get",
      `${API_BASE_URL}get/payments`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const onClose = () => {
    setShowFilters(false);
  };

  const handlePayemntUpdateModal = (record)=>{
    setOpenPaymentUpdateModal(true);
    setPayment(record.id);
  }
  /**
   * @var columns
   * @description columns for the payments table 
   */
  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
      width: 18,
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "name",
      width: 70,
    },
    {
      title: "StudentRegNo",
      dataIndex: "reg_no",
      key: "reg_no",
      width: 50,
    },
    {
      title: "Payment Method",
      dataIndex: ["payment_method", "name"],
      key: "payment_method",
      width: 38,
    },
    {
      title: "Payer's Name",
      dataIndex: "guardian_name",
      key: "guardian_name",
      width: 60,
    },
    {
      title: "Payer's Contact",
      dataIndex: "guardian_contact",
      key: "guardian_contact",
      width: 45,
    },
    {
      title: "Term",
      dataIndex: ["term", "termName"],
      key: "term",
      width: 40,
    },
    {
      title: "Date",
      dataIndex: "payment_date",
      key: "payment_date",
      width: 50,
    },
    {
      title: "School Pay Code",
      dataIndex: "school_pay_code",
      key: "school_pay_code",
      width: 106,
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
      width: 120,
    },
    {
      title: "cheque No",
      dataIndex: "cheque_no",
      key: "cheque_no",
      width: 76,
    },
    {
      title: "Amount Paid (UGX)",
      dataIndex: "amount",
      key: "amount",
      fixed: "right",
      width: 46,
      render: (text) => <span>{parseInt(text).toLocaleString()}</span>,
    },
    {
      title:"Actions",
      key: "action",
      fixed: "right",
      width:38,
      render: (text, record, index) => (
       (formData?.term === "" || formData.term === settings.currentTerm) &&  <button
          onClick={() => {
            handlePayemntUpdateModal(record)
          }}
          className="block  px-4 py-2 m-4  hover:bg-gray-300 text-left text-white bg-gradient-to-r from-green-400 via-green-500 to-green-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-green-300 dark:focus:ring-green-200  font-medium rounded-lg text-sm"
        >
          Update
        </button>
      ),
    }
  ];

  useEffect(() => {
    fetchPayments();
    fetchPaymentMethods();
  }, []);

  useEffect(() => {
    fetchPayments();
  }, [formData])
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            View Fee Payments Table
          </h1>
          <div className="hidden lg:flex float-end">
            <button
              onClick={toggleFilters}
              className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
            >
              Filters
            </button>
          </div>
          <Table
            dataSource={loading ? Array(10).fill({}) : payments}
            columns={columns}
            scroll={{ x: 2500, y: 600 }}
            pagination={{ pageSize: 50 }}
            className=" whitespace-nowrap"
            locale={{
              emptyText: loading ? (
                <Skeleton active paragraph={{ rows: 16 }} />
              ) : (
                <Empty />
              ),
            }}
          />
        </div>
      </div>
      <Drawer
        title="Filters"
        placement="right"
        closable={true}
        onClose={onClose}
        open={showFilters}
        getContainer={false}
      >
        <div className="mb-4 relative h-11 w-full min-w-[200px]">
          <input
            className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
            id="regNo"
            name="regNo"
            type="text"
            placeholder=" "
            value={formData.regNo}
            onChange={handleChange}
          />
          <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[4.1] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-green-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:!border-green-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:!border-green-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
            Registration Number *
          </label>
        </div>
        <div className="relative h-11 w-full min-w-[200px]">
          <label className="">Filter By Term</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Term"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full 
                           "
              onChange={onChangeTerm}
              onSearch={onSearch}
              filterOption={filterTermOption}
              size="large"
              options={termOptions}
            />
          </div>
        </div>
        <div className="relative  h-11 w-full min-w-[200px] mt-9">
          <label className="">Filter By Year</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Year"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
              onChange={onChangeYear}
              onSearch={onSearch}
              filterOption={filterYearOption}
              size="large"
              options={yearOptions}
            />
          </div>
        </div>
        <div className="relative  h-11 w-full min-w-[200px] mt-9">
          <label className="">Filter By Payment Method</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <Select
              showSearch
              placeholder="Select Payment Method"
              optionFilterProp="children"
              className="
                           h-full w-full rounded-md border border-blue-gray-200 bg-transparent font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full "
              onChange={onChangePaymentMethod}
              onSearch={onSearch}
              filterOption={filterPaymentMethodOption}
              size="large"
              options={paymentMethodOptions}
            />
          </div>
        </div>
        <div className="relative h-11 w-full min-w-[200px] mt-7">
          <label className="">Select Start Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              type="date"
              id="startDate"
              name="startDate"
              value={formData.startDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="relative h-11 w-full min-w-[200px] mt-7">
          <label className="">Select End Date</label>
          <div className="relative h-11 w-full min-w-[200px]">
            <input
              className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full"
              type="date"
              id="endDate"
              name="endDate"
              value={formData.endDate}
              onChange={handleChange}
            />
          </div>
        </div>
        <Divider />
        <div className="flex justify-between mt-4">
          <button
            type="button"
            className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
            onClick={() => {
              setShowFilters(false);
            }}
          >
            Apply Filters
          </button>
          <button
            onClick={resetFilters}
            className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
          >
            Reset Filters
          </button>
        </div>
      </Drawer>
      <PaymentAmountUpdateModal
        paymentId={payment}
        open={openPaymentUpdateModal}
        setOpen={setOpenPaymentUpdateModal}
      />
    </div>
  );
}

export default ViewPayments;
