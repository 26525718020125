import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { logoutUser } from "./components/redux/slice/authSlice";
import { refreshToken } from "./components/redux/slice/authSlice";

import ViewFeeStructure from "./components/StudentAccouting/js/Fees/ViewFeeStructure";
import CreateFeeStructure from "./components/StudentAccouting/js/Fees/CreateFeeStructure";
import CreateFeeTypes from "./components/StudentAccouting/js/Fees/CreateFeeTypes";
import ViewFeeTypes from "./components/StudentAccouting/js/Fees/ViewFeeTypes";
import RegisterPayments from "./components/StudentAccouting/js/payments/RegisterPayments";
import ViewPayments from "./components/StudentAccouting/js/payments/ViewPayments";
import StudentFinancialStatement from "./components/StudentAccouting/js/payments/StudentFinancialStatement";
import FeeDefaulters from "./components/StudentAccouting/js/payments/FeeDefaulters";
import FeesSummary from "./components/StudentAccouting/js/Fees/FeesSummary";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import EditedPayments from "./components/StudentAccouting/js/payments/EditedPayments";
import ViewSalaryStructure from "./components/Payroll/js/salary/ViewSalaryStructure";
import AddSalaryStructure from "./components/Payroll/js/salary/AddSalaryStructure";
import AddExpenses from "./components/Payroll/js/payments/AddExpenses";
import AddSalaryPayment from "./components/Payroll/js/payments/AddSalaryPayment";
import AddAllowances from "./components/Payroll/js/allowances/AddAllowances";
import ViewAllowances from "./components/Payroll/js/allowances/ViewAllowances";
import ViewStaff from "./components/Payroll/js/staff/ViewStaff";
import AssignSalary from "./components/Payroll/js/staff/AssignSalary";
import ViewExpenses from "./components/Payroll/js/payments/ViewExpenses";
import ViewSalaryPayments from "./components/Payroll/js/payments/ViewPayments";
import ViewExpenseCategory from "./components/Payroll/js/payments/ViewExpenseCategory";
import AddExpenseCategory from "./components/Payroll/js/payments/AddExpenseCategory";
import AddIncomes from "./components/Payroll/js/incomes/AddIncomes";
import ViewIncomes from "./components/Payroll/js/incomes/ViewIncomes";
import ExpenseSubCatModal from "./components/Payroll/js/payments/ExpenseSubCatModal";
import AddAsset from "./components/Payroll/js/assets/AddAsset";
import ViewAsset from "./components/Payroll/js/assets/ViewAsset";

function App() {
  const [showTokenExpirationAlert, setShowTokenExpirationAlert] =
useState(false);
const navigate = useNavigate();
const dispatch = useDispatch();

  /**
   * @description Function to check if the token is about to expire
   * @param {*} token
   * @param {*} thresholdSeconds
   * @returns true / false
   */
  const isTokenAboutToExpire = (token, thresholdSeconds) => {
    const decodedToken = jwtDecode(token);
    const currentTimestamp = Math.floor(Date.now() / 1000); // Current timestamp in seconds
    const expiryTimestamp = decodedToken.exp; // Expiry timestamp from the token

    // Calculate remaining time until expiry
    const remainingTime = expiryTimestamp - currentTimestamp;

    // Check if remaining time is less than the threshold
    return remainingTime <= thresholdSeconds;
  };
  // trigger expiration alert
  const checkTokenExpiration = () => {
    const token = localStorage.getItem("user");
    if (token) {
      const aboutToExpire = isTokenAboutToExpire(token, 300);
      if (aboutToExpire) {
        setShowTokenExpirationAlert(true);
        showExpirationAlert();
      } 
    }
  };

  // expiration alert
  const showExpirationAlert = () => {
    if (!showTokenExpirationAlert) {
      Swal.fire({
        title: "Your session is about to expire",
        text: "Please refresh your token.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Refresh Token",
      }).then((result) => {
        if (result.isConfirmed) {
          refreshUserToken();
        }
      });
    }
  };

  // refresh token
  const refreshUserToken = async () => {
    try {
      // Dispatch refreshToken action
      const response = await dispatch(refreshToken());
      if (response) {
        localStorage.removeItem("user");
      }

      Swal.fire({
        title: "Token refreshed successfully!",
        icon: "success",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/"); // Navigate to the /farms route after clicking OK
          setShowTokenExpirationAlert(false);
        }
      });
      localStorage.setItem("user", response.data.token);
    } catch (error) {
      // If token refresh fails, dispatch logoutUser action
      await dispatch(logoutUser());
      Swal.fire({
        title: "Token refreshed failed!",
        text: "You will be logged out & Redirected to login page",
        icon: "error",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/"); // Navigate to the /farms route after clicking OK
          dispatch(logoutUser())
          setShowTokenExpirationAlert(false);
        }
      });
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 5 * 60 * 1000); // run every five minutes

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/feesummary" element={<FeesSummary />} />
        <Route path="/create/feeStructure" element={<CreateFeeStructure />} />
        <Route path="/create/feeType" element={<CreateFeeTypes />} />
        <Route path="/view/feeStructure" element={<ViewFeeStructure />} />
        <Route path="/view/feeTypes" element={<ViewFeeTypes />} />
        <Route path="/add/payment" element={<RegisterPayments />} />
        <Route path="/view/payments" element={<ViewPayments />} />
        <Route path="/view/editedpayments" element={<EditedPayments/>}/>
        <Route
          path="/view/financial-statements"
          element={<StudentFinancialStatement />}
        />
        <Route path="/view/feesDefaulters" element={<FeeDefaulters />} />

        {/* payroll paths */}
        <Route path="/view/salary-structures" element={<ViewSalaryStructure/>}/>
        <Route path="/add-salary" element={<AddSalaryStructure/>}/>
        <Route path="/add-expense" element={<AddExpenses/>}/>
        <Route path="/add-payment" element={<AddSalaryPayment/>}/>
        <Route path="/add-allowances" element={<AddAllowances/>}/>
        <Route path="/view/allowances" element={<ViewAllowances/>}/>
        <Route path="/view-staff" element={<ViewStaff/>}/>
        <Route path="/assign-salary" element={<AssignSalary/>}/>
        <Route path="/view-expenses" element={<ViewExpenses/>}/>
        <Route path="/view-payments" element={<ViewSalaryPayments/>}/>
        <Route path="/add-expense-categories" element={<AddExpenseCategory/>}/>
        <Route path="/view-expense-categories" element={<ViewExpenseCategory/>}/>
        <Route path="/addIncome" element={<AddIncomes/>}/>
        <Route path="/viewIncome" element={<ViewIncomes/>}/>
        <Route path="/add-expense-subcat" element={<ExpenseSubCatModal/>}/>
        <Route path="/add-asset" element={<AddAsset/>}/>
        <Route path="/view-asset" element={<ViewAsset/>}/>
      </Routes>
    </div>
  );
}

export default App;
