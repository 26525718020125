import React, { useEffect, useState } from "react";
import { Table, Divider, Drawer } from "antd";
import Nav from "../../../nav";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

function EditedPayments() {
  const [editedPayments, setEditedPayments] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [formData, setFormData] = useState({
    startDate:"",
    endDate:""
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
};

  const fetchEditedPayments = () => {
    const onSuccess = (response) => {
      setEditedPayments(response.editedPayments);
    };
    const onError = (error) => {};
    let params = {
        start_date: formData.startDate,
                    end_date: formData.endDate,
    }
    apiRequest(
      "get",
      `${API_BASE_URL}edited-payments`,
      "",
      params,
      onSuccess,
      onError
    );
  };
  const onClose = () => {
    setShowFilters(false);
  };
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const resetFilters = () => {
   setFormData({
    startDate:"",
    endDate:""
   })
  };

  const columns = [
    {
      title: "Date Changed",
      dataIndex: "date_changed",
      key: "date_changed",
      width: 120,
    },
    {
      title: "Old Amount",
      dataIndex: "old_amount",
      key: "old_amount",
      render: (text) => parseInt(text).toLocaleString(),
      width: 120,
    },
    {
      title: "New Amount",
      dataIndex: "new_amount",
      key: "new_amount",
      render: (text) => parseInt(text).toLocaleString(),
      width: 120,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      width: 250,
    },
    {
      title: "Student Name",
      dataIndex: "student_name",
      key: "student_name",
      width: 120,
    },
    {
      title: "Registration Number",
      dataIndex: "reg_no",
      key: "reg_no",
      width: 120,
    },

    {
      title: "Changed By",
      dataIndex: "changed_by",
      key: "changed_by",
      width: 120,
    },
    {
      title: "Guardian Name",
      dataIndex: ["fees_payment", "guardian_name"],
      key: "guardian_name",
      width: 120,
    },
    {
      title: "Guardian Contact",
      dataIndex: ["fees_payment", "guardian_contact"],
      key: "guardian_contact",
      width: 120,
    },
  ];
  useEffect(() => {
    fetchEditedPayments();
  }, []);
  useEffect(() => {
    fetchEditedPayments();
  }, [formData.startDate, formData.endDate]);
  return (
    <div>
      {" "}
      <Nav />{" "}
      <div className="p-4 sm:ml-64">
        {" "}
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
        <h1 className="text-2xl justify-center items-center">
            View Fees Structure Table
          </h1>
          <div className="hidden lg:flex float-end">
        <button
          onClick={toggleFilters}
          className="float-end text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-200  font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        >
          Filters
        </button></div>
          {" "}
          <Table
            dataSource={editedPayments}
            columns={columns}
            pagination={{
                pageSize: 50,
              }}
              scroll={{
                y: 240,
              }}
          />
        </div>
      </div>
      <Drawer
          title="Filters"
          placement="right"
          closable={true}
          onClose={onClose}
          open={showFilters}
          getContainer={false}
        >
          <div className="relative h-11 w-full min-w-[200px] mt-3">
            <label className="">Select Start Date</label>
            <div className="relative h-11 w-full min-w-[200px]">
            <input className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full" type="date" id="startDate" name="startDate" value={formData.startDate} onChange={handleChange} />
            </div>
          </div>
          <div className="relative h-11 w-full min-w-[200px] mt-6">
            <label className="">Select End Date</label>
            <div className="relative h-11 w-full min-w-[200px]">
            <input className="peer h-full w-full rounded-md border border-blue-gray-200 bg-transparent px-3 py-3 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-green-500 focus:border-t-transparent focus:outline-0 sm:w-full" type="date" id="endDate" name="endDate" value={formData.endDate} onChange={handleChange} />
            </div>
          </div>
          <Divider />
          <div className="flex justify-between mt-4">
            <button
              type="button"
              className=" mt-4 bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-blue-500 hover:border-transparent"
              onClick={() => {
                setShowFilters(false);
              }}
            >
              Apply Filters
            </button>
            <button
              onClick={resetFilters}
              className=" mt-4 bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border rounded-full border-red-500 hover:border-transparent"
            >
              Reset Filters
            </button>
          </div>
        </Drawer>
    </div>
  );
}

export default EditedPayments;
