import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Nav from "../../../nav";
import { useSelector } from "react-redux";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";
import ClassPieChart from "./charts/ClassPieChart";

function FeesSummary() {
  const {settings} = useSelector((state) => state.settings.settings)
  const [loading, setLoading] = useState(true);
  const [feeSummary, setFeeSummary] = useState([]);
  const [schoolFeeDefaulters, setSchoolFeesDefaulters] = useState([]);
 

  const fetchFeeSummary = () => {
    setLoading(true);
    const onSuccess = (response) => {
      setFeeSummary(response);
     setLoading(false)
    };
    let params;
    if (settings) {
      params = {
        year_id: settings[0]?.currentYear,
        term_id: settings[0]?.currentTerm,
      };
    }
    const onError = (error) => {
      toast.error(error?.response?.data?.error);
     setLoading(false)
    };

    apiRequest(
      "get",
      `${API_BASE_URL}fees-expected`,
      "",
      params,
      onSuccess,
      onError
    );
  };

  /**
   * @function getWholeSchoolFeesDefualter
   * @description get all the fees defaullter for the whole school based on the term and year
   */
  const getWholeSchoolFeesCollection = () => {
    const onSuccess = (response) => {
      setSchoolFeesDefaulters(response.data);
      // toast.success(response.message);
      setLoading(false);
    };
    const onError = (error) => {
      toast.error(
        error.response ? error?.response?.data?.message : "Something went wrong"
      );
     setLoading(false)
    };
    let params = {
      year_id:  settings[0]?.currentYear,
      term_id:  settings[0]?.currentTerm,
    };

    apiRequest(
      "get",
      `${API_BASE_URL}school-fees-summary`,
      "",
      params,
      onSuccess,
      onError
    );
  };
 

  useEffect(() => {
    if (settings[0]?.currentYear && settings[0]?.currentTerm) {
      fetchFeeSummary();
      getWholeSchoolFeesCollection();
    }
  }, [settings]);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            Fee Collection Summary
          </h1>
          <div class="flex">
            <div class="w-1/2 p-4">
              <div class="bg-blue-200 rounded-lg p-4">
                <div class="font-bold mb-2">Total Fees Expected</div>
                <div class="text-blue-900">
                  UGX {parseInt(feeSummary?.data?.total_expected_fees ? feeSummary?.data?.total_expected_fees : 0).toLocaleString()}
                </div>
              </div>
            </div>
            <div class="w-1/2 p-4">
              <div class="bg-green-200 rounded-lg p-4">
                <div class="font-bold mb-2">Total Collected Fees</div>
                <div class="text-green-900">
                  UGX {parseInt(feeSummary?.data?.total_collected_fees ? feeSummary?.data?.total_collected_fees : 0 ).toLocaleString()}
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4 border border-b-2 rounded-lg px-4 py-8">
              {loading ? <div>loading .... <div className="border rounded-circle"></div></div>: Object.keys(schoolFeeDefaulters).map((className) => (
                <div key={className} className="flex flex-col items-center">
                  <h2>{className}</h2>
                  <ClassPieChart
                    data={schoolFeeDefaulters[className].default}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeesSummary;
