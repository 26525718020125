import React, { useState, useEffect } from "react";
import {  Table, Skeleton, Empty } from "antd";
import { toast } from "react-toastify";
import Nav from "../../../nav";
import apiRequest from "../../../common/apiRequest";
import { API_BASE_URL } from "../../../common/constants";

function ViewFeeTypes() {
  const [feeTypes, setFeeTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchFeeTypes = () => {
    setLoading(true);
    const onSuccess =(response)=>{
      setFeeTypes(response.feeTypes);
      setLoading(false);
    }
    const onError=(error)=>{
      toast.error("Failed to retreive Fee Types");
      setLoading(false);
    }

    apiRequest(
      'get',
      `${API_BASE_URL}get/feeTypes`,
      "",
      "",
      onSuccess,
      onError

    );
  }

  const columns = [
    {
      title: "No",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => <strong>{index + 1}</strong>,
      width: 10,
    },
    {
      title: "Name of Fee",
      dataIndex: "name",
      key: "name",
      width: 250,
    },
    {
      title: "Description of fee",
      dataIndex: "description",
      key: "description",
      width: 150,
    },
  ];

  useEffect(() => {
  fetchFeeTypes();
  }, []);
  return (
    <div>
      <Nav />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-2 border-gray-200 border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-2xl justify-center items-center">
            Fee Types Table
          </h1>
          <Table
            dataSource={loading ? Array(10).fill({}) : feeTypes}
            columns={columns}
            scroll={{ x: true, y: 700 }}
            pagination={{ pageSize: 50 }}
            className=" whitespace-nowrap"
            locale={{
              emptyText: loading ? (
                <Skeleton active paragraph={{ rows: 16 }} />
              ) : (
                <Empty />
              ),
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default ViewFeeTypes;
